import React from "react";
import "./OrderIdWidget.css";

export type OrderWidgetProps = {
  id?: string;
};

export default class OrderIdWidget extends React.Component<OrderWidgetProps> {
  render(): React.ReactNode {
    const { id } = this.props;

    return (
      <div className="OrderIdWidgetContainer">
        <p>Order #{id}</p>
      </div>
    );
  }
}
