import React from 'react';

class ChannelSinglePageScreen extends React.Component {
    render() {
        return (
            <div className="container h-100 d-flex" style={{ backgroundColor: 'aliceblue' }}>
                <div className="row align-self-center" style={{ width: '105%' }}>
                    <div className="col-12 mx-auto text-center">
                        <h5><b>Hello, Selamat datang. Ada yang bisa kami bantu?</b></h5>
                        <h6>Silahkan klik logo dibawah</h6><br></br>
                        <a rel="noreferrer" id="custom-button-1" href="#;"><img width="80" src={global.ImgUrl + 'shopable/9937B910-0DF2-452B-A6DE-F5C47529F3CD.png'} alt="io" /></a>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChannelSinglePageScreen;