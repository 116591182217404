import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { ShowErrorModel, ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { ShareUrlModel } from "../../CommonComponents/UI/ShareUrlPopUp/Models/ShareUrlModel";
import { getPreloadProps } from "../../CommonComponents/Utils";
import MyPageResponse from "./Models/MyPageResponse";

export default class MyPageViewModel2 extends ViewModel {
    isCreator: boolean = false;
    creatorId: string = "";
    numberInCart: number = 0;
    isPreloadingLoaded: boolean = false;
    isLoading: boolean;
    errorMessage: ShowErrorModel | null = null;
    model: MyPageResponse | null = null;
    shareUrlModel: ShareUrlModel | null = null;
    snackbarMessage: ShowSnackbarModel | null = null;
    isLogOutLoading: boolean = false;

    constructor() {
        super();
        this.isLoading = localStorage.fpAuth;
    }

    loadPreloadingProps() {
        this.emit(() => this.isLoading = true);
        
        getPreloadProps({
            onSuccess: (isCreator, creatorId, numberInCart) => {
                this.emit(() => {
                    this.isCreator = isCreator;
                    this.creatorId = creatorId;
                    this.numberInCart = numberInCart;
                    this.isPreloadingLoaded = true;
                });
                
                this.loadDetail();
            },
            onFailure: (error) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorMessage = {
                        message: error,
                        onRetryButtonTapped: () => this.loadPreloadingProps()
                    }
                });
            }
        })
    }

    loadDetail() {
        this.emit(() => {
            this.isLoading = true;
            this.errorMessage = null;
        });

        requestAPI(Endpoints.myPage, {
            onSuccess: (response: BaseResponse<MyPageResponse>) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.model = response.data;
                });
            },
            onFailure: (error) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorMessage = {
                        message: error,
                        onRetryButtonTapped: () => this.loadDetail()
                    }
                });
            }
        })
    }

    shareLink() {
        this.emit(() => {
            this.shareUrlModel = new ShareUrlModel({
                urlString: this.model?.seller?.shareUrl ?? ""
            });
        });
    }

    logOut() {
        this.emit(() => {
            this.isLogOutLoading = true;
        })
        
        requestAPI(Endpoints.logOut, {
            onSuccess: (_) => {
                this.emit(() => this.isLogOutLoading = false);
                
                localStorage.removeItem("fpAuth");
                window.location.href = "/Login";
            },
            onFailure: (error) => { 
                this.emit(() => {
                    this.isLogOutLoading = false;
                    this.snackbarMessage = new ShowSnackbarModel({message: error});
                });
            }
        });
    }
}