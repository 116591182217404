import React from "react";
import "./CheckoutNoteWidget.css";

type CheckoutNoteWidgetProps = {
  onNoteChange: (event: any) => void;
  note?: string | null;
};

export default class CheckoutNoteWidget extends React.Component<CheckoutNoteWidgetProps> {
  render() {
    return (
      <div
        id="CheckoutNoteWidget"
        className="container"       
      >
        <textarea
          value={this.props.note || ""}
          className="note"
          onChange={this.props.onNoteChange}
          placeholder="Enter delivery notes"
        />
      </div>
    );
  }
}
