import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import CouponResponse from "./Models/CouponResponse";
import { getPreloadProps } from "../../CommonComponents/Utils";
import { ShowErrorModel, ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import ViewModel from "../../CommonComponents/Base/ViewModel";

export default class CouponViewModel extends ViewModel {
    couponData : CouponResponse | null = null;
    isLoading: boolean = true;
    isLoadingButtonSubmit: boolean = false;
    errorModel: ShowErrorModel | null = null;
    errorSnackBarModel: ShowSnackbarModel | null = null;
    errorMessage: string | null = null;
    navBarTransparency: number = 1;
    numberInCart: number = 0;
    snackbarMessage: ShowSnackbarModel | null = null;
    enteredCouponCode: string = "";

    claimCoupon() {
      this.emit(() => {
        this.isLoadingButtonSubmit = true;
      })
      requestAPI(Endpoints.claimCoupon, {
        onFailure: (error) => {
          this.emit(() => {
            
            this.isLoadingButtonSubmit = false;
            this.snackbarMessage = new ShowSnackbarModel({
              message: error,
              type: "error",
              inset: {
                bottom: 52
              }
            })
          })
        },
        onSuccess: () => {
         this.useCouponCode(this.enteredCouponCode);
         this.apply();
          this.emit(() => {
            this.isLoadingButtonSubmit = false;
          })
        },
        parameters: {
          couponCode: this.enteredCouponCode
        }
      })
    }

    handleEnteredCouponCodeChange(code?: string) {
      this.emit(() => {
        this.enteredCouponCode = code || ""
      })
    }

    loadPreloadingProps() {
        this.isLoading = true;
        this.emit(() => {});

        getPreloadProps({
            onSuccess: (_, __, numberInCart) => {
                this.numberInCart = numberInCart;
                this.emit(() => {});

                this.loadCoupon();
            },
            onFailure: (error) => {
                this.isLoading = false;
                this.errorModel = {
                    message: error,
                    onRetryButtonTapped: () => this.loadPreloadingProps()
                };

                this.emit(() => {});
            }
        })
    }

    loadCoupon() {
        this.isLoading = true;
        this.emit(() => {});
        
        requestAPI(Endpoints.coupons, {
            parameters: {
                fpAuth: localStorage.fpAuth
            },
            onSuccess: (response: BaseResponse<CouponResponse>) => {
                this.isLoading = false;
                this.couponData = response.data;
                this.emit(() => {});
            },
            onFailure: (error: string) => {
                this.isLoading = false;
                this.errorModel = {
                    message: error,
                    onRetryButtonTapped: () => this.loadPreloadingProps()
                };

                this.emit(() => {});
            }
        })
    }

    apply() {
       if(localStorage.point !== undefined)
        {
            localStorage.removeItem("point")
        }
        window.location.href = '/Checkout';
    }

    useCouponCode(code?: string) {
      if (code) {
        localStorage.setItem("use_coupon", "1")
        localStorage.setItem("couponCode", code)
      }
    }

    handleUseCoupon = (couponId: string, couponName: string) => {
        localStorage.setItem("use_coupon", "1")
        localStorage.setItem("couponId", couponId)
        localStorage.setItem("couponName", couponName)
    }

    onSnackbarIsFinished() {
        this.errorMessage = null;
        this.emit(() => {});
    }
}