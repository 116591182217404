import React from "react";
import 'bootstrap/dist/js/bootstrap.min.js';

export default class ProductDetailFaqWidget extends React.Component<{}> {
    render() {
        return(
            <div className="AccordionFAQ">
                <div className="accordion" id="myAccordion2">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne2">
                                <strong>Apakah produk original?</strong>
                            </button>						
                        </h2>
                        <div id="collapseOne2" className="accordion-collapse collapse show" data-bs-parent="#myAccordion2">
                            <div className="card-body">
                                <p>Kami mengimpor produk branded langsung dari official brand di Korea atau negara lain (USA, Jepang, dll). Sehingga kami jamin semua produk 100% original.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo2">
                                <strong>Apa yang dimaksud international order? Butuh waktu berapa lama?</strong>
                            </button>
                        </h2>
                        <div id="collapseTwo2" className="accordion-collapse collapse" data-bs-parent="#myAccordion2">
                            <div className="card-body">
                                <p>Kami memiliki stock di warehouse Korea. Setelah kamu melakukan order, produk yang tersedia di Korea akan segera dikirim menggunakan jasa pengiriman yang kami miliki. Biasanya membutuhkan waktu 7-14 hari pengiriman dari Korea atau negara lain (USA, Jepang, dll) untuk menuju warehouse kami di Jakarta.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree2">
                                <strong>Apakah ada tambahan biaya untuk international delivery</strong>
                            </button>                     
                        </h2>
                        <div id="collapseThree2" className="accordion-collapse collapse" data-bs-parent="#myAccordion2">
                            <div className="card-body">
                                <p>Tidak, kami sudah memasukkan biaya international delivery dan pajak pada harga produk, jadi kamu hanya membayar harga yang sudah tertera dan ongkos kirim dari warehouse kami di Jakarta menuju alamatmu. Jadi "TIDAK ADA TAMBAHAN BIAYA" selain harga yang tertera pada halaman checkout di platform kami.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour2">
                                <strong>Apa itu Ready Stock?</strong>
                            </button>                     
                        </h2>
                        <div id="collapseFour2" className="accordion-collapse collapse" data-bs-parent="#myAccordion2">
                            <div className="card-body">
                                <p>Kami meiliki stock di warehouse Jakarta untuk 1 day delivery. Kami akan langsung mengirimkan paketmu setelah kamu memesannya. Dan kami juga memberikkan gratis ongkos kirim untuk produk ready stock kami.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFive">
                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive2">
                                <strong>Apakah ada gratis ongkir?</strong>
                            </button>                     
                        </h2>
                        <div id="collapseFive2" className="accordion-collapse collapse" data-bs-parent="#myAccordion2">
                            <div className="card-body">
                                <p>Kami memberikan JNE gratis ongkir hingga Rp. 30,000 dengan min order diatas Rp. 1,200,000. Dan jangan lupa cek halaman kuponmu, karena terkadang kami memberikan promo kupon gratis ongkir pada beberapa event.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}