import React from "react";
import {
  AttributeItemModel,
  AttributeModel,
  AttributeType,
} from "../../ProductDetailViewModel";
import HypeButton from "../HypeButton";
import QuantityButton from "../QuantityButton";
import ProductDetailVariantPicker from "./ProductDetailVariantPicker";
import "./ProductDetailVariantPickerSheet.css";

export type ProductDetailVariantPickerSheetProps = {
  title?: string;
  description?: string;
  imageUrl?: string;
  selectedVariants?: string[];
  attributes?: AttributeModel[];
  onUnderlayClick?: () => void;
  isVisible?: boolean;
  onCloseClick?: () => void;
  onAttributeItemClick?: (
    type: AttributeType,
    attribute?: AttributeItemModel,
    stock?: number,
    isPreoder?: boolean
  ) => void;
  onAddToBagClick?: () => void;
  shouldDisableAddToBagButton?: boolean;
  selectedMapAttributes?: Record<AttributeType, AttributeItemModel>;
  type?: AttributeType;
  qty?: number;
  onAdd?: () => void;
  onSub?: () => void;
  shouldDisableQtyButton?: boolean;
  attributeOptions?: AttributeType[];
};

export default class ProductDetailVariantPickerSheet extends React.Component<ProductDetailVariantPickerSheetProps> {
  getPrevKey(k: AttributeType): AttributeType | undefined {
    const prevIndex = (this.props.attributeOptions || []).findIndex(
      (x) => x === k
    );

    return this.props.attributeOptions?.[(prevIndex || 0) - 1] as AttributeType;
  }

  render() {
    // if (!this.props.isVisible) return null;
    return (
      <div
        id="ProductDetailVariantPickerSheetContainer"
        style={{ pointerEvents: this.props.isVisible ? "auto" : "none" }}
      >
        <div
          id={
            this.props.isVisible
              ? "ProductDetailVariantPickerSheetActive"
              : "ProductDetailVariantPickerSheet"
          }
        >
          <div id="ProductDetailVariantPickerSheetCloseHandle">
            <button onClick={() => this.props.onCloseClick?.()}>Close</button>
          </div>
          <div id="ProductDetailVariantPickerSheetProductInfo">
            <img
              alt="product"
              src={this.props.imageUrl}
              id="ProductDetailVariantPickerSheetImage"
            />
            <div style={{ marginLeft: "12px" }}>
              <p id="ProductDetailVariantPickerSheetProductTitle">
                {this.props.title}
              </p>
              <p id="ProductDetailVariantPickerSheetProductDescription">
                {this.props.description}
              </p>
            </div>
          </div>
          <div
            style={{ flexGrow: 1, paddingBottom: "108px", overflow: "auto" }}
          >
            {this.props.attributes?.map((a) => (
              <div
                id="ProductDetailVariantPickerSheetProductOptions"
                key={a.type}
              >
                <ProductDetailVariantPicker
                  attribute={a}
                  onAttributeItemClick={(x, stock, isPreOder) => {
                    this.props.onAttributeItemClick?.(
                      a.type,
                      x,
                      stock,
                      isPreOder
                    );
                  }}
                  type={a.type}
                  selectedMapAttributes={this.props.selectedMapAttributes}
                  title={a.title}
                  // @ts-ignore
                  isDisabled={
                    this.props.attributeOptions?.[0] === a.type
                      ? false
                      : !this.props.selectedMapAttributes?.[
                          this.getPrevKey(a.type)!!
                        ]
                  }
                />
              </div>
            ))}
            <div
              style={{
                paddingLeft: 24,
                paddingRight: 24,
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 12,
              }}
            >
              <QuantityButton
                isDisabled={this.props.shouldDisableQtyButton}
                qty={this.props.qty}
                onAdd={() => {
                  this.props.onAdd?.();
                }}
                onSub={() => {
                  this.props.onSub?.();
                }}
              />
            </div>
          </div>

          <div id="ProductDetailVariantPickerSheetFooterSection">
            <HypeButton
              label="Add To Bag"
              isDisabled={this.props.shouldDisableAddToBagButton}
              onClick={() => {
                this.props.onAddToBagClick?.();
              }}
            />
          </div>
        </div>
        {this.props.isVisible && (
          <div
            id="ProductDetailVariantPickerSheetUnderlay"
            onClick={() => {
              if (!this.props.isVisible) return;
              this.props.onUnderlayClick?.();
            }}
          />
        )}
      </div>
    );
  }
}
