import Scaffold, {
  ShowSnackbarModel,
} from "../../CommonComponents/UI/Scaffold/Scaffold";
import ChannelChatPopup from "../../CommonComponents/UI/ChannelChatPopup/ChannelChatPopup";
import ErrorPopupMessageWidget from "../../CommonComponents/UI/PopupWidget/ErrorPopupMessage/ErrorPopupMessageWidget";
import { useParams } from "react-router-dom";
import ProductDetailViewModel from "./ProductDetailViewModel";
import ProductDetailImageWidget from "./Widgets/ProductDetailImageWidget";
import ProductDetailMainInformationWidget from "./Widgets/ProductDetailMainInformationWidget";
import ProductDetailCreatorInformationWidget from "./Widgets/ProductDetailCreatorInformationWidget";
import ProductDetailOptionWidget from "./Widgets/ProductDetailOptionWidget";
import ProductDetailFaqWidget from "./Widgets/ProductDetailFaqWidget";
import ProductDetailInformationWidget from "./Widgets/ProductDetailInformationWidget";
import ProductDetailDescriptionWidget from "./Widgets/ProductDetailDescriptionWidget";
import ButtonWidget, {
  ButtonWidgetType,
} from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";

import "./ProductDetail.css";
import ic_thefepi_f from "../../Assets/Images/Logo/ic_fepi_f.svg";
import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
import {
  convertToRupiah,
  getLocationQueries,
  getScreenSizeWithMaxWidth,
} from "../../CommonComponents/Utils";
import ViewController from "../../CommonComponents/Base/ViewController";
import PopUpWidgetModel from "../../CommonComponents/UI/PopUp/Models/PopUpWidgetModel";
import ProductDetailVariantPicker from "./Widgets/ProductDetailVariantPicker/ProductDetailVariantPicker";
import ProductDetailVariantPickerSheet from "./Widgets/ProductDetailVariantPicker/ProductDetailVariantPickerSheet";
import HypeButton from "./Widgets/HypeButton";
import ProductSizeTableWidget from "../../CommonComponents/UI/ProductSizeTable/ProductSizeTableWidget";

export default function ProductDetailScreen() {
  let { productId } = useParams();
  const queries = getLocationQueries();
  const sellerId = queries["creator"];
  localStorage.seller_id = sellerId;
  return <ProductDetailPage productId={productId ?? ""} />;
}

class ProductDetailPage extends ViewController<
  { productId: string },
  ProductDetailViewModel
> {
  constructor(props: { productId: string }) {
    super(props, new ProductDetailViewModel(props.productId));
    this.viewModel.loadPreloadingProps();
    window.addEventListener("scroll", () => this.handleScrolling());
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", () => this.handleScrolling());
  }

  handleSubmit = (event: any) => {
    this.viewModel.onAddToCart();
  };

  closeModal = () => {
    document.body.classList.remove("modal-open");
    this.viewModel.onCloseModal();
  };

  render() {
    const productDetail = this.viewModel.productDetail;
    const attributes = this.viewModel.attributes;
    const DeeplinkHeight = 46;
    const fixedHeight = 72;
    return (
      <Scaffold
        isShowLoading={this.viewModel.isLoading}
        showSnackbar={this.viewModel.snackbarMessage}
        showErrorModel={this.viewModel.errorModel}
      >
        <div id="PRODUCT_DETAIL" onScroll={() => this.handleScrolling()}>
          <div
            id="ProductDetailImageCoverContainer"
            style={{
              height: `${(getScreenSizeWithMaxWidth().width * 4) / 3}px`,
            }}
          >
            <ProductDetailImageWidget
              screenWidth={this.state.width}
              productDetail={productDetail}
            />
          </div>
          {productDetail && (
            <div id="ProductDetailCreatorSection">
              <ProductDetailCreatorInformationWidget
                productDetail={productDetail}
              />
            </div>
          )}
          <div id="ProductDetailTopInfoContainer">
            <ProductDetailMainInformationWidget
              mainInformation={productDetail}
            />
            <ProductDetailVariantPicker
              oneLiner
              onAttributeItemClick={(x, stock, isPreOrder) => {
                if (this.viewModel.primaryAttribute?.type && x) {
                  this.viewModel.toggleSheet();
                  this.viewModel.selectAttributeItem(
                    this.viewModel.primaryAttribute?.type,
                    x,
                    stock || 0,
                    isPreOrder || false
                  );
                  this.viewModel.loadAttributes(
                    this.viewModel.getWhatKeyShouldWeLoadAfter(
                      this.viewModel.primaryAttribute.type
                    )
                  );
                }
              }}
              onSeeAllClick={() => {
                // console.log('xxxxxxxxxx')
                const a = this.viewModel.getFirstSelectableAttributeItem();
                // console.log({a})
                if (!this.viewModel.primaryAttribute || !a) return;
                this.viewModel.selectAttributeItem(
                  this.viewModel.primaryAttribute?.type,
                  a?.attribute,
                  a?.stock || 0,
                  a.isPreOrder || false
                );
                this.viewModel.loadAttributes(
                  this.viewModel.getWhatKeyShouldWeLoadAfter(
                    this.viewModel.primaryAttribute.type
                  )
                );
                this.viewModel.toggleSheet();
              }}
              type={this.viewModel.primaryAttribute?.type}
              selectedMapAttributes={this.viewModel.selectedMapAttributes}
              title={this.viewModel.attributeOptions.join(", ")}
              attribute={this.viewModel.primaryAttribute}
              shouldShowSeeAll
            />
            <ProductDetailVariantPickerSheet
              attributeOptions={this.viewModel.attributeOptions}
              onCloseClick={() => this.viewModel.toggleSheet()}
              onUnderlayClick={() => {
                this.viewModel.toggleSheet();
              }}
              attributes={this.viewModel.attributes}
              description={this.viewModel.productName}
              imageUrl={this.viewModel.productDetail?.productImageUrls?.[0]}
              selectedMapAttributes={this.viewModel.selectedMapAttributes}
              title={this.viewModel.productDetail?.brand.name}
              isVisible={this.viewModel.shouldShowVariantPickerSheet}
              qty={this.viewModel.desiredQty}
              shouldDisableQtyButton={
                !(
                  this.viewModel.attributeOptions.length ===
                  Object.keys(this.viewModel.selectedMapAttributes).length
                )
              }
              onAdd={() => {
                const attr =
                  this.viewModel.selectedMapAttributes[
                    this.viewModel.getLastKey()
                  ];
                // console.log({attr})
                console.log({ attr });
                if (this.viewModel.desiredQty < attr.stock || attr.isPreOrder) {
                  this.viewModel.addDesiredQty();
                }
              }}
              onSub={() => {
                if (this.viewModel.desiredQty > 1) {
                  this.viewModel.subDesiredQty();
                }
              }}
              onAttributeItemClick={(type, attr, stock, isPreOrder) => {
                // console.log({type, attr, stock, isPreOrder})
                this.viewModel.emit(() => {
                  this.viewModel.desiredQty = 1;
                });

                const prevKey = this.viewModel.getPrevKey(type);

                if (prevKey) {
                  if (!this.viewModel.selectedMapAttributes[prevKey]) {
                    this.viewModel.emit(() => {
                      this.viewModel.snackbarMessage = new ShowSnackbarModel({
                        message: `Select ${prevKey.toLowerCase()} first.`,
                        inset: {
                          bottom: 64,
                        },
                        type: "normal",
                      });
                    });
                    return;
                  }
                }

                if (attr) {
                  this.viewModel.selectAttributeItem(
                    type,
                    attr,
                    stock || 0,
                    isPreOrder || false
                  );
                  this.viewModel.loadAttributes(
                    this.viewModel.getWhatKeyShouldWeLoadAfter(type)
                  );
                }
              }}
              onAddToBagClick={() => {
                this.viewModel.onAddToCart();
              }}
              shouldDisableAddToBagButton={
                Object.keys(this.viewModel.selectedMapAttributes).length <
                  this.viewModel.attributeOptions.length ||
                this.viewModel.desiredQty <= 0
              }
            />
          </div>

          <ProductDetailDescriptionWidget
            reviewCount={productDetail?.review.reviewSummary.totalReviews}
            productId={this.props.productId}
            productDetail={productDetail}
            tabId={this.viewModel.tabId}
            onTabClicked={(tabId) => this.viewModel.onchangeTab(tabId)}
          />
          <div className="accordion" id="size-chart-section">
            <div className="accordion-item" style={{borderColor: 'transparent'}}  >
              <div className="accordion-header" style={{height: 36, alignItems: 'center', display: 'flex', paddingLeft: 24, paddingRight: 24}}>
                <button
                className="size-chart-button accordion-button collapsed"
                style={{
                  fontWeight: '600',
                  fontSize: '12px',
                  boxShadow: 'none',
                  lineHeight: '18px',
                  display: 'flex',
                  alignItems: 'center',

                  color: '#000000'
                  ,
                  borderColor: 'transparent'

                }}
                  data-bs-toggle="collapse"
                  data-bs-target={`#size-chart-content`}
                >Size Details</button>
              </div>
              <div
                id={`size-chart-content`}
                className={`accordion-collapse collapse`}
                style={{ padding: 24 }}
                data-bs-parent="#size-chart-section"
              >
                <ProductSizeTableWidget
                  headers={this.viewModel.sizes.map((s) => s.label)}
                  rows={this.viewModel.sizes.reduce((acc, { items }) => {
                    const columnCount = this.viewModel.sizes.length;
                    acc.push(
                      Array.from({ length: columnCount }).map(
                        (_, index) => items[index]
                      )
                    );
                    return acc;
                  }, [] as Array<string[]>)}
                />
              </div>
            </div>
          </div>
          <div style={{ margin: "24px 16px 0px 16px" }}>
            <ProductDetailInformationWidget />
            <ProductDetailFaqWidget />
          </div>

          <div
            style={{
              height: "4px",
              width: "inherit",
              backgroundColor: "var(--whiteGrey)",
            }}
          />
          <button
            onClick={() =>
              (window.location.href =
                "/SizeChart/" + productDetail?.brand.id + "/2")
            }
            style={{
              margin: "0px 16px",
              display: "flex",
              width: "calc(100% - 32px)",
              height: "42px",
              alignItems: "center",
            }}
          >
            <p className="font14" style={{ flexGrow: "1", textAlign: "left" }}>
              <b>Size Chart</b>
            </p>
            <p className="font16" style={{ flexShrink: "0" }}>
              {">"}
            </p>
          </button>

          {/* <div
            style={{
              height: "4px",
              width: "inherit",
              backgroundColor: "var(--whiteGrey)",
            }}
          /> */}
          {/* <div
            style={{
              margin: "0px 16px",
              // display:"flex",
              width: "calc(100% - 32px)",
              // alignItems:"center"
              paddingTop: 12,
              paddingBottom: 12,
            }}
          >
            <p className="font14" style={{ flexGrow: "1", textAlign: "left", marginBottom: 12 }}>
              <b>Recommendations</b>
            </p>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                gap: 3
              }}
            >
              {this.viewModel.recommendations.map((r) => {
                return (
                  <div style={{ width: "100%", position: 'relative' }}>
                    {r.isSoldOut && <div style={{padding: '4px 8px 4px 8px', background: '#F77462', position: 'absolute', left:0, top: 0, fontWeight: 700, fontSize: 10, color: '#fff'}}>SOLD OUT</div>}
                   
                    <img
                      style={{
                        width: "100%",
                        height: (this.state.width / 3) * 1.32,
                      }}
                      src={r.imageUrl}
                    />
                    <p id="RecTitle">{r.title}</p>
                    <p id="RecPrice">{convertToRupiah(r.price.netPrice)}</p>
                    <p>
                      <span id="RecRealPrice">{convertToRupiah(r.price.realPrice)}</span>
                      <span id="RecDiscountPercentage">{r.price.discount}%</span>
                    </p>
                  </div>
                );
              })}
            </div>
          </div> */}
          <div
            style={{
              height: "4px",
              width: "inherit",
              backgroundColor: "var(--whiteGrey)",
            }}
          />
          <div
            id="ProductTopGradient"
            className="row"
            style={{
              bottom: `${DeeplinkHeight + fixedHeight - 75}px`,
              width: "inherit",
            }}
          />
          <div id="ProductDetailBottomContainer">
            <div className="col-2 text-center ptop15">
              <img
                data-bs-toggle="modal"
                data-bs-target="#ChatModal"
                width="20"
                className="img-fluid"
                src={global.ImgUrl + "shopable/ic-detail-chat@2x.png"}
                alt="gambar1"
              ></img>
            </div>
            <div style={{ flexGrow: 1 }}>
              <HypeButton
                isDisabled={this.viewModel.productDetail?.isSoldOut}
                label="Add to Bag"
                renderLeftIcon={() => {
                  return (
                    <div style={{ marginRight: 8 }}>
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line
                          x1="13"
                          y1="4.5"
                          x2="13"
                          y2="19.5"
                          stroke="white"
                          stroke-linecap="round"
                        />
                        <line
                          x1="5"
                          y1="11.5"
                          x2="20"
                          y2="11.5"
                          stroke="white"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                  );
                }}
                onClick={() => {
                  const a = this.viewModel.getFirstSelectableAttributeItem();
                  if (!this.viewModel.primaryAttribute || !a) return;
                  this.viewModel.selectAttributeItem(
                    this.viewModel.primaryAttribute?.type,
                    a?.attribute,
                    a?.stock || 0,
                    a.isPreOrder || false
                  );
                  this.viewModel.loadAttributes(
                    this.viewModel.getWhatKeyShouldWeLoadAfter(
                      this.viewModel.primaryAttribute.type
                    )
                  );

                  this.viewModel.toggleSheet();
                }}
              />
            </div>
          </div>
          <div id="SpaceNavigationBackground" style={{ opacity: "0" }} />
          <div id="SpaceHomeNavContainer">
            <button
              id="SpaceHomeButton"
              onClick={() => (window.location.href = "/")}
            >
              <img src={ic_thefepi_f} alt="" />
            </button>
            <div id="ProductNameNavbar" style={{ opacity: "0" }}>
              <span>
                {this.viewModel.productName.length > 19
                  ? productDetail?.productName.substring(0, 19) + "..."
                  : productDetail?.productName}
              </span>
            </div>
            <button
              id="SpaceCartButton"
              style={{ top: 0 }}
              onClick={() => {
                window.location.href = "/Cart";
              }}
            >
              <img src={ic_cart} alt="" />
              {(this.viewModel.numberInCart ?? 0) > 0 && (
                <div>{this.viewModel.numberInCart}</div>
              )}
            </button>
          </div>
        </div>

        <ErrorPopupMessageWidget
          showModal={this.viewModel.showModal}
          Message={this.viewModel.Message}
          closeModal={() => {
            this.closeModal();
          }}
        />

        <ChannelChatPopup />
      </Scaffold>
    );
  }

  private handleScrolling() {
    const containerElement = document.querySelector("#PRODUCT_DETAIL");
    const containerRect = containerElement?.getBoundingClientRect();
    let transparencyValue =
      (containerRect?.y ?? 0) / -((this.state.width * 3) / 2 + 16);
    transparencyValue = transparencyValue > 1 ? 1 : transparencyValue;

    const navBar = document.querySelector("#SpaceNavigationBackground");
    const titleInNavBar = document.querySelector("#ProductNameNavbar");

    (navBar as any).style.opacity = transparencyValue;
    (titleInNavBar as any).style.opacity = transparencyValue;
  }
}
