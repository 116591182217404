import React from "react";

type ButtonWidgetProps = {
    inputType?: string,
    title?: string,
    isEnabled?: boolean,
    errorMessage?: string,
    width?: string | number,
    placeholder?: string,
    maxLength?: number,
    value?: string,
    action?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default class InputFieldWidget extends React.Component<ButtonWidgetProps, {}> {
    render() {
        const isDisable = this.props.isEnabled === false;
        return (
            <>
                <label className="form-label">{this.props.title}</label>
                <input 
                    className="InputWidgetNoBorder"
                    type={this.props.inputType} 
                    maxLength= {this.props.maxLength}
                    onChange={isDisable ? undefined : this.props.action} 
                    placeholder={this.props.placeholder}
                    autoComplete="off"
                    value={this.props.value}
                >
                </input>
                <div className="divError">
                    {
                    this.props.errorMessage !== "" && this.props.errorMessage !== null &&
                        <span className="errormsg"><img alt="ic-wrong" width="10" src={global.ImgUrl+'shopable/ic-wrong@3x.png'}></img> &nbsp;&nbsp; {this.props.errorMessage}</span>
                    }
                </div>
            </>
        );
    }
}