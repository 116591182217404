import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import OrderReceiptResponse from "./Models/OrderReceiptResponse";
import { getPreloadProps } from "../../CommonComponents/Utils";
import { ShowErrorModel, ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import ReactPixel from 'react-facebook-pixel';
import ViewModel from "../../CommonComponents/Base/ViewModel";

export default class PointViewModel extends ViewModel {
    orderReceiptData : OrderReceiptResponse | null = null;
    isLoading: boolean = true;
    isLoadingButtonSubmit: boolean = false;
    errorMessage: string | null = null;
    errorModel: ShowErrorModel | null = null;
    errorSnackBarModel: ShowSnackbarModel | null = null;
    navBarTransparency: number = 1;
    numberInCart: number = 0;
    snackbarMessage: ShowSnackbarModel | null = null;

    constructor() {
      super();
      if (!localStorage.getItem('orderId')) {
        window.location.href = "/";
      }
    }

    loadPreloadingProps() {
        this.isLoading = true;
        this.emit(() => {});
        localStorage.removeItem("selectedBagItem");
        localStorage.removeItem("address_id");
        localStorage.removeItem("address_id_edit");
        localStorage.removeItem("address_name");
        localStorage.removeItem("scity_edit");
        localStorage.removeItem("scity_id_edit");
        localStorage.removeItem("ship_name");
        localStorage.removeItem("ship_phone");
        localStorage.removeItem("ship_email");
        localStorage.removeItem("ship_address");
        localStorage.removeItem("ship_postCode");
        localStorage.removeItem("courier_id");
        localStorage.removeItem("courier_name");
        localStorage.removeItem("includeShippingInsurance");
        localStorage.removeItem("point");
        localStorage.removeItem("use_coupon");
        localStorage.removeItem("couponId");
        localStorage.removeItem("couponCode");
        localStorage.removeItem("deliveryNotes");

        getPreloadProps({
            onSuccess: (_, __, numberInCart) => {
                this.numberInCart = numberInCart;
                this.emit(() => {});

                this.loadOrderReceipt();
            },
            onFailure: (error) => {
                this.isLoading = false;
                this.errorModel = {
                    message: error,
                    onRetryButtonTapped: () => this.loadPreloadingProps()
                };

                this.emit(() => {});
            }
        })
    }

    loadOrderReceipt() {
        this.isLoading = true;
        this.emit(() => {});
        
        requestAPI(localStorage.fpAuth === undefined ? Endpoints.orderReceiptDevice : Endpoints.orderReceipt, {
            parameters: {
                fpAuth: localStorage.fpAuth,
                deviceId: localStorage.uuid,
                orderId: localStorage.orderId
            },
            onSuccess: (response: BaseResponse<OrderReceiptResponse>) => {
                console.log(response.data)
                this.isLoading = false;
                this.orderReceiptData = response.data;
                this.emit(() => {});
                    
                window.gtag('event', 'purchase', {
                    "transaction_id": response.data.orderNumber,
                    "affiliation": "THEFEPI",
                    "value": response.data.billing.total,
                    "currency": "IDR",
                    "tax": 0,
                    "shipping": 0,
                    "items": [
                        response.data.orderItems[0].items.map((items,index) => ( 
                        {
                            "id": items.product_id,
                            "name": items.name,
                            "list_name": "Search Results",
                            "brand": items.brand,
                            "category": "Apparel/T-Shirts",
                            "variant": items.attribute,
                            "list_position": index,
                            "quantity": items.quantity,
                            "price": items.price.netPrice
                        }))
                    ]
                });
                
                //TRACK GOOGLE
                window.gtag('event', 'conversion', {
                    'send_to': 'UA-75502214-1',
                    'value': response.data.billing.total,
                    'currency': 'IDR'
                });
                
                if (window.location.host === 'm.thefepi.com') {
                    //TRACK FB
                    var product_id_arr: string[] = [];
                    response.data.orderItems[0].items.map((items) => ( 
                        product_id_arr.push(items.product_id)
                    ));

                    var brand_name_arr: string[] = [];
                    response.data.orderItems[0].items.map((items) => ( 
                        brand_name_arr.push(items.brand)
                    ));
                    var data = {
                        "contents": [
                            response.data.orderItems[0].items.map((items) => ( 
                            {
                                "id": items.product_id,
                                "product_id": items.product_id,
                                "quantity": items.quantity,
                                "item_price": items.price.netPrice,
                                "brand_name": items.brand,
                            }))
                        ],
                        "content_ids": product_id_arr,
                        "content_name": brand_name_arr,
                        "content_type": "product",
                        "value": response.data.billing.total,
                        "currency": "IDR",
                    };
                    ReactPixel.track('Purchase', data);
                }
                localStorage.removeItem('orderId');
            },
            onFailure: (error: string) => {
                console.log(error);
            }
        })
    }

    onSnackbarIsFinished() {
        this.errorMessage = null;
        this.emit(() => {});
    }
}