import React from "react";
import { convertToRupiah } from "../../../CommonComponents/Utils";
import ProductDetailResponse from "../Models/ProductDetailResponse";
import ProductDetailBadgeWidget from "./ProductDetailBadgeWidget";

type ProductDetailMainInformationWidgetProps = {
    mainInformation: ProductDetailResponse | null
};

export default class ProductDetailMainInformationWidget extends React.Component<ProductDetailMainInformationWidgetProps> {
    render() {
        const mainInformation = this.props.mainInformation;

        return(
            <div id="ProductMainInformationContainer">
                <p className="productName">{mainInformation?.productName}</p>
                <div id="brandNameDiv">
                    <a href={"/Brand/"+mainInformation?.brand.id}>
                        <img width="14" src="https://images.thefepi.com/file/shopable/ic-brand-name.png" alt="" ></img>
                        <span className="brandName">{mainInformation?.brand.name}</span>
                    </a>
                    {
                        (this.props.mainInformation?.isNew || this.props.mainInformation?.isSoldOut || (this.props.mainInformation?.badgeUrls.length ?? 0) > 0) &&
                        <ProductDetailBadgeWidget
                            productDetail={this.props.mainInformation}
                            width={40}
                        />
                    }
                    <div className="list-inline" id="priceDiv">
                        <span className="list-inline-item realPrice">{convertToRupiah(mainInformation?.price.netPrice)}</span>
                        {
                            mainInformation?.price.discount !== 0 &&
                            <>
                            <span className="list-inline-item netPrice">{convertToRupiah(mainInformation?.price.realPrice)}</span>
                            <span className="list-inline-item discount">{mainInformation?.price.discount} %</span>
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}