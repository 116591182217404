import React from "react";
import "./CheckoutShippingAddressWidget.css";
import { Optional } from "../../../../CommonTypes";

type CheckoutShippingAddressWidgetProps = {
  addressId: Optional<string>;
  addressDetails?: Optional<string>;
  addressName?: Optional<string>;
  addressEmail?: Optional<string>;
  orderId: string;
  onClick?: () => void;
};

export default class CheckoutShippingAddressWidget extends React.Component<CheckoutShippingAddressWidgetProps> {
  render() {
    const { addressDetails, addressName, addressEmail, orderId } =
      this.props;

    const shouldShowEmpty = !addressDetails || !addressName || !addressEmail;

    return (
      <a id="CheckoutShippingAddressWidget" className="container" href={`/Checkout/AddressSelector?orderId=${orderId}`}>
        <div className="address">
          {shouldShowEmpty ? (
            <div
              className="buttonContentEmpty"
            >
              Add shipping address
            </div>
          ) : (
            <>
              <p className="name">{addressName}</p>
              <p className="details">{addressDetails}</p>
              <p className="email">{addressEmail}</p>
            </>
          )}
        </div>
        <div className="icon">
          <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L7 7L1 13"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </a>
    );
  }
}
