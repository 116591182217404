import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import ChannelChatPopup from "../../CommonComponents/UI/ChannelChatPopup/ChannelChatPopup";
import PaymentViewModel from "./PaymentViewModel";
import { Navigate  } from "react-router-dom";

import "./Payment.css";
import ic_thefepi_f from "../../Assets/Images/Logo/ic_fepi_f.svg";
import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
import imgOtherPayment from "../../Assets/Images/imgOtherpayment.svg";
import ViewController from "../../CommonComponents/Base/ViewController";

export default class CheckoutScreen extends ViewController<{}, PaymentViewModel> {
    constructor(props: {}) {
        super(props, new PaymentViewModel());
    }

    handleChangePayment = () => {
    }

    handleSubmitBCA = () => {
        this.viewModel.handleSubmitBCA();
    }

    handleSubmitMidtrans = () => {
        this.viewModel.handleSubmitMidtrans();
    }
    
    render() {
        if (localStorage.getItem("selectedBagItem") === null) {
            return <Navigate  to='/'/>;
        }

        return(
            <Scaffold 
                isShowLoading={false}
                showSnackbar={this.viewModel.snackbarMessage}
                showErrorModel={this.viewModel.errorModel}
            >
                
                <div id="Payment" className="container">
                    <p className="font14 margin0"><b>Payment Method</b></p>
                    <br></br>
                    <div className="box_putih">
                        <div className="row">
                            <div className='col' style={{padding:'15px 20px'}}>
                                <img alt="bca" width="40" src={global.ImgUrl+'shopable/img-bca@3x.png'}></img> 
                                <span className="paymentTitle">&nbsp;&nbsp;BCA Bank Transfer</span>
                                <p className="ptop20">
                                    Direct Bank Transfer to BCA
                                    <br></br><br></br>
                                    7140-788-788
                                    <br></br><br></br>
                                    Account Name PT IDE PICUP GROUP
                                    <br></br><br></br>
                                    Please make payment to our account within 1 working day.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <button className="btn_payment" onClick={() => {this.handleSubmitBCA()}}>BCA Bank Transfer</button>
                            </div>
                        </div>
                    </div>
                    
                    <br></br>
                    <div className="box_putih">
                        <div className="row">
                            <div className='col' style={{padding:'15px 20px'}}>
                                <img alt="bca" width="40" src={imgOtherPayment}></img> 
                                <span className="paymentTitle">&nbsp;&nbsp;Other Payment Method</span>
                                <p className="ptop20">
                                    Bank Transfer - Mandiri / BNI<br></br>
                                    Card payment - visa / master / JCB<br></br>
                                    Virtual Accont - BCA / Permata / BNI<br></br>
                                    Pembayaran Instant - Mandiri / BNI<br></br>
                                    ATM Lain - Prima / Bersama / Alto<br></br>
                                    E-money - Gopay<br></br>
                                    Cicilan - Akulaku<br></br>
                                    Counter - Alfamart / Indomaret
                                </p>
                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <button className="btn_payment" onClick={() => {this.handleSubmitMidtrans()}}>Other Payment Method</button>
                            </div>
                        </div>
                    </div>
                    
                    <div id="SpaceNavigationBackground" className="row" style={{opacity:`${this.viewModel.navBarTransparency}`}}></div>                
                    <div className="row floatingButton">
                        <button id="SpaceHomeButton" onClick={() => window.location.href = "/"}>
                            <img src={ic_thefepi_f} alt="" />
                        </button>
                        <button id="SpaceCartButton" onClick={() => {
                            window.location.href = "/Cart";
                        }}>
                            <img src={ic_cart} alt="" />
                            {
                                (this.viewModel.numberInCart ?? 0) > 0 && <div>{this.viewModel.numberInCart}</div>
                            }
                        </button>
                    </div>

                    <ChannelChatPopup />
                </div>

                
            </Scaffold>
        );
    }
} 