import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import NavigationButton from "../../CommonComponents/UI/ButtonWidget/NavigationButton";
import MyPageViewModel from "./MyPageViewModel";
import MyPageProfileSectionWidget from "./Widgets/MyPageProfileSectionWidget";
import { convertToRupiah } from "../../CommonComponents/Utils";
import MyPageActionButton from "./Widgets/MyPageActionButton";
import LoginRequestPageWidget from "../../CommonComponents/UI/LoginRequestPage/LoginRequestPageWidget";
import ButtonWidget, { ButtonWidgetType } from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";

import "./MyPageScreen.css";

import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
import ic_right_chevron_black from "../../Assets/Images/ic_right_chevron_black.svg";
import ic_shop from "../../Assets/Images/ic_shop_icon.svg";
import ic_thefepi_f from "../../Assets/Images/Logo/ic_fepi_f.svg";
import ic_thefepi_full from "../../Assets/Images/Logo/ic_fepi_full.svg";
import ViewController from "../../CommonComponents/Base/ViewController";
import MyPageNumberOfOrdersDetailWidget from "./Widgets/MyPageNumberOfOrdersDetailWidget";

export default class MyPageScreen extends ViewController<{}, MyPageViewModel> {
    constructor(props: {}) {
        super(props, new MyPageViewModel());

        if (localStorage.fpAuth) {
            this.viewModel.loadPreloadingProps();
        }
    }

    render() {
        const customerModel = this.viewModel.model?.customer;
        const sellerModel = this.viewModel.model?.seller;

        console.log(this.viewModel.model)

        return(<Scaffold 
            topBar={
                <>
                    <NavigationButton
                        src={ic_thefepi_f} 
                        onClick={() => window.location.href = "/"} 
                    />
                    <p style={{fontSize:"16px", fontWeight:"bold", marginLeft:"8px"}}>My Page</p>
                    <div style={{flexGrow:1}}/>
                    <NavigationButton 
                        src={ic_cart} 
                        numberOnRightTop={this.viewModel.numberInCart} 
                        onClick={() => {
                            window.location.href = "/Cart";
                        }}
                    />
                </>
            } 
            selectedBottomBarItem="my-page"
            creatorId={this.viewModel.creatorId}
            isCreator={this.viewModel.isCreator}
            isShowLoading={this.viewModel.isLoading}
            showErrorModel={this.viewModel.errorMessage}
            showLoadingAndErrorPosition={this.viewModel.isPreloadingLoaded ? "on-content-view" : "fullscreen"}
            shareUrlModel={this.viewModel.shareUrlModel}
            showSnackbar={this.viewModel.snackbarMessage}
            showDownloadInvitationWidget={true}
        >
            {
                localStorage.fpAuth ?
                <div id="MyPageContentContainer">
                    {
                        sellerModel ?
                        <MyPageProfileSectionWidget 
                            imageUrl={sellerModel.profileImageUrl ?? ""} 
                            name={customerModel?.username ?? ""}
                            followerDetail={sellerModel.followerDescriptionLabel}
                            points={sellerModel.points}
                            coupons={sellerModel.coupons}
                            level={sellerModel.sellerLevel}
                        /> :
                        (
                            customerModel &&
                            <MyPageProfileSectionWidget 
                                imageUrl={customerModel.profileImageUrl ?? ""} 
                                name={customerModel.username}
                                points={customerModel.points}
                                coupons={customerModel.coupons}
                                level={customerModel.customerLevel}
                            />
                        )
                    }
                    {
                        customerModel?.sellerRegistrationUrl &&
                        <MyPageActionButton onClick={() => window.location.href = (customerModel.sellerRegistrationUrl ?? "")} backgroundColor="var(--lightPink)">
                            <img src={ic_shop} alt="" />
                            <p style={{color:"var(--pink)"}}>Apply as Creator</p>
                        </MyPageActionButton>
                    }
                    {
                        customerModel?.registrationProgressMessage &&
                        <MyPageActionButton backgroundColor="var(--lightPurple)">
                            <p style={{color:"var(--purple)"}}>{customerModel.registrationProgressMessage}</p>
                        </MyPageActionButton>
                    }
                    {
                        sellerModel &&
                        <>
                            <p id="MyPageSectionTitle">Creator</p>
                            <div id="MyPageMyRewardContainer">
                                <p>My Reward</p>
                                <p>{convertToRupiah(sellerModel.revenue.amount)}</p>
                                <p>{sellerModel.revenue.description}</p>
                            </div>
                            <MyPageActionButton onClick={() => window.location.href = `/${this.viewModel.creatorId}`}>
                                <p>Manage Space</p>
                            </MyPageActionButton>
                            <MyPageActionButton onClick={() => this.viewModel.shareLink()}>
                                <p>Share My Space</p>
                            </MyPageActionButton>
                        </>
                    }
                    <p id="MyPageSectionTitle">Order</p>
                    <div id="MyPageOrderSectionContainer">
                        <div>
                            <p>My Order & Delivery</p>
                            <button
                                onClick={() => {
                                    window.location.href = "/OrderAndDelivery";
                                }}
                            ><p>See mode</p><img src={ic_right_chevron_black} alt="" /></button>
                        </div>
                        <div />
                        <div>
                            {(this.viewModel.model?.customer.orderSummary || []).map(os => 
                              <MyPageNumberOfOrdersDetailWidget 
                                label={os.label}
                                isSomethingNew={os.isThereNew}
                                value={os.count ?? 0}
                            />)}
                          
                        </div>
                    </div>
                     <p id="MyPageSectionTitle">Other</p>
                      <MyPageActionButton onClick={() => window.location.href = "https://api.whatsapp.com/send?phone=6282288886784&text=Hello%20TheFepi&source=&data="}>
                            <div style={{display: 'flex', alignItems: 'center'}}> <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginRight: 8 }}
        >
          <path
            d="M10 13V13C10 9.68629 12.6863 7 16 7V7C19.3137 7 22 9.68629 22 13V13"
            stroke="black"
          />
          <rect x="17" y="19" width="3" height="2" rx="1" stroke="black" />
          <path d="M22 18V18C22 19.1046 21.1046 20 20 20V20" stroke="black" />
          <path
            d="M8 15C8 13.8954 8.89543 13 10 13H11V18H10C8.89543 18 8 17.1046 8 16V15Z"
            stroke="black"
          />
          <path
            d="M24 16C24 17.1046 23.1046 18 22 18L21 18L21 13L22 13C23.1046 13 24 13.8954 24 15L24 16Z"
            stroke="black"
          />
          <path
            d="M3 7V21C3 23.2091 4.79086 25 7 25H13.1716C13.702 25 14.2107 25.2107 14.5858 25.5858L16.2929 27.2929C16.6834 27.6834 17.3166 27.6834 17.7071 27.2929L19.4142 25.5858C19.7893 25.2107 20.298 25 20.8284 25H25C27.2091 25 29 23.2091 29 21V7C29 4.79086 27.2091 3 25 3H7C4.79086 3 3 4.79086 3 7Z"
            stroke="black"
          />
        </svg><p>Help</p></div>
                      </MyPageActionButton>
                    <div style={{height:"32px"}}/>
                    <ButtonWidget 
                        action={() => this.viewModel.logOut()} 
                        buttonType={ButtonWidgetType.lightPink}
                        isLoading={this.viewModel.isLogOutLoading}
                    >
                        Log Out
                    </ButtonWidget>
                    <button onClick={() => {
                      window.location.href = "/TermService"
                    }} style={{
                      marginTop: 28,
                      fontStyle: "normal",
                      fontWeight: 400,
                      display: 'block',
                      fontSize: "11.5px",
                      lineHeight: "17px",

                      /* achromatic/medium grey */

                      color: "#999999"}}
                    >
                    Terms of Service
                    </button>
                    <button onClick={() => {
                      window.location.href = "/PrivacyPolicy"
                    }} style={{
                      marginTop: 16,
                      fontStyle: "normal",
                      display: 'block',
                      fontWeight: 400,
                      fontSize: "11.5px",
                      lineHeight: "17px",

                      /* achromatic/medium grey */

                      color: "#999999"}}
                    >
                   Privacy Policy
                    </button>
                    <div id="MyPageTheFepiLogoContainer">
                        <img src={ic_thefepi_f} alt="" />
                        <div>
                            <img src={ic_thefepi_full} alt="" />
                            <div />
                            <p>m.thefepi.com</p>
                        </div>
                    </div>
                </div> :
                <LoginRequestPageWidget />
            }
        </Scaffold>);
    }
}