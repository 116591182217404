import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import ChannelChatPopup from "../../CommonComponents/UI/ChannelChatPopup/ChannelChatPopup";
import OrderReceiptViewModel from "./OrderReceiptViewModel";
import OrderReceiptBillingWidget from "./Widgets/OrderReceiptBillingWidget";
import OrderReceiptItemsWidget from "./Widgets/OrderReceiptItemsWidget";
import OrderReceiptAddressWidget from "./Widgets/OrderReceiptAddressWidget";
import OrderReceiptPaymentInfoWidget from "./Widgets/OrderReceiptPaymentInfoWidget";
import ButtonWidget, { ButtonWidgetType } from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";

import "./OrderReceipt.css";
import ic_thefepi_f from "../../Assets/Images/Logo/ic_fepi_f.svg";
import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
import ViewController from "../../CommonComponents/Base/ViewController";

export default class OrderReceiptScreen extends ViewController<{}, OrderReceiptViewModel> {
    constructor(props: {}) {
        super(props, new OrderReceiptViewModel());
        this.viewModel.loadPreloadingProps();
    }

    componentWillUnmount(): void {
      localStorage.removeItem('orderId');
    }
    
    shopMore = () =>{
        window.location.href = '/';
    }

    render() {
        const DeeplinkHeight = 46;
        const fixedHeight = 72;
        const orderReceiptData = this.viewModel.orderReceiptData;

        return(
            <Scaffold 
                isShowLoading={this.viewModel.isLoading}
                showSnackbar={this.viewModel.snackbarMessage}
                showErrorModel={this.viewModel.errorModel}
            >
                
                <div id="OrderReceipt" className="container">
                    <div className="row">
                        <div className='box_white_cart'>
                            <div className="col no-padding text-center">
                                <br></br>
                                <img className='img-fluid' width="60" src={global.ImgUrl+'shopable/ic-complete@2x.png'} alt="img_complete"></img>
                                <p className="font18 mtop10"><b>Order completed !</b></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='box_white_cart'>
                            <div className="row">
                                <div className="col-6">
                                    <span className="font14 color585b63"><b>Order Number</b></span>
                                </div>
                                <div className="col-6 text-end">
                                    <span className="font14 color585b63"><b>#{this.viewModel.orderReceiptData?.orderNumber}</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <OrderReceiptItemsWidget
                        orderReceiptData={orderReceiptData}
                    />

                    <OrderReceiptBillingWidget
                        orderReceiptData={orderReceiptData}
                    />

                    <OrderReceiptAddressWidget
                        orderReceiptData={orderReceiptData}
                    />

                    <OrderReceiptPaymentInfoWidget 
                        orderReceiptData={orderReceiptData}
                    />

                    <div className="row">
                        <div className="col no-padding">
                            <div className='box_white_cart'>
                                <p><b>Need Help?</b></p>
                                <div className="div_ask" data-bs-toggle="modal" data-bs-target="#ChatModal">
                                    <img src={global.ImgUrl+'shopable/ic-detail-chat.png'} alt="img_chat"></img>&nbsp;&nbsp;Ask Anytime
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="CartTopGradient"  className="row" style={{bottom:`${(DeeplinkHeight+fixedHeight)-75}px`}}/>
                    <div className="row fixed">
                        <div className="col-12 text-center paddingtop10">
                            <ButtonWidget 
                                buttonType={ButtonWidgetType.primary} 
                                isLoading={this.viewModel.isLoadingButtonSubmit ? true : false}
                                isEnabled={true}
                                width="calc(100% - 6px)"
                                action={() => {
                                    this.shopMore();
                                }} 
                            >
                                Shop More
                            </ButtonWidget>
                        </div>
                    </div>

                    <div id="SpaceNavigationBackground" className="row" style={{opacity:`${this.viewModel.navBarTransparency}`}}></div>                
                    <div className="row floatingButton">
                        <button id="SpaceHomeButton" onClick={() => window.location.href = "/"}>
                            <img src={ic_thefepi_f} alt="" />
                        </button>
                        <button id="SpaceCartButton" onClick={() => {
                            window.location.href = "/Cart";
                        }}>
                            <img src={ic_cart} alt="" />
                            {
                                (this.viewModel.numberInCart ?? 0) > 0 && <div>{this.viewModel.numberInCart}</div>
                            }
                        </button>
                    </div>
                    <ChannelChatPopup />
                </div>

                
            </Scaffold>
        );
    }
} 