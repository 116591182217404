
import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import PaymentResponse from "./Models/PaymentResponse";
import { ShowErrorModel, ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { Optional } from "../../CommonTypes";
import { getLocationQueries } from "../../CommonComponents/Utils";
import ViewModel from "../../CommonComponents/Base/ViewModel";

export default class CartViewModel extends ViewModel {
    isLoading: boolean = true;
    isLoadingButtonSubmit: boolean = false;
    errorModel: ShowErrorModel | null = null;
    errorSnackBarModel: ShowSnackbarModel | null = null;
    errorMessage: string | null = null;
    Message: string | null = null;
    isSuccess: boolean = false;
    snackbarMessage: ShowSnackbarModel | null = null;
    isEnableBtn: boolean = false;
    navBarTransparency: number = 1;
    numberInCart: number = 0;
    fpAuth = localStorage.getItem('fpAuth');
    deviceId = localStorage.getItem('uuid');
    orderId: Optional<string>;
    point: Optional<number>;
    couponId: Optional<string>;
    couponCode: Optional<string>;
    shippingAddressId: Optional<string>;
    includeInsurances: Optional<string[]>;
    courierIds: Optional<string[]>;
    deliveryNotes: Optional<string>;

    isSignedIn: boolean = Boolean(localStorage.fpAuth);

    oldProps =  {
      courierId: localStorage.getItem("courier_id"),
      orderId: localStorage.getItem("orderId"),
      point: localStorage.getItem("point"),
      deliveryNotes: localStorage.getItem("deliveryNotes"),
      includeShippingInsurance: localStorage.getItem('includeShippingInsurance'),
      shippingAddressId: localStorage.getItem("address_id"),
    }



    constructor() {
      super();
      const params = new URLSearchParams(window.location.search);
      this.orderId = params.get('orderId');
      this.point = parseInt(params.get('point') || '0');
      this.couponId = params.get('couponId');
      this.couponCode = params.get('couponCode');
      this.shippingAddressId = params.get('shippingAddressId');
      this.includeInsurances = params.get('includeInsurances')?.split(',') || [];
      this.courierIds = params.get('courierIds')?.split(',') || [];
      this.deliveryNotes = params.get('deliveryNotes');
    }

    changeNavBarTransparency(value: number) {
        this.navBarTransparency = value;
        this.emit(() => {});
    }

    handleSubmitBCA() {
        requestAPI(this.isSignedIn ? Endpoints.directBCA :  Endpoints.directBCADevice , {
            parameters: this.isSignedIn ? {
                fpAuth: localStorage.fpAuth,
                deviceId: localStorage.uuid,
                point: this.point,
                couponId: this.couponId,
                shippingAddressId: this.shippingAddressId,
                courierIds: this.courierIds,
                includeInsurances: this.includeInsurances,
                deliveryNotes: this.deliveryNotes, 
                orderId: this.orderId,
            } : {
               fpAuth: localStorage.fpAuth,
                deviceId: localStorage.uuid,
                orderId: this.oldProps.orderId,
                shippingAddressId: getLocationQueries()["shippingAddressId"]
            },
            onSuccess: (response: BaseResponse<PaymentResponse>) => {
                window.location.href = '/OrderReceipt';
            },
            onFailure: (error: string) => {
                console.log(error)               
                this.emit(() => {                  
                  this.errorMessage = error;
                })
                 this.emit(() => {  
                  this.snackbarMessage = new ShowSnackbarModel({
                    message: error,
                    type: 'error'
                  });
                })
            }
        })
    }

    handleSubmitMidtrans() {
        requestAPI(localStorage.fpAuth === undefined ? Endpoints.checkoutOrderDevice : Endpoints.checkoutOrder, {
            parameters: {
                fpAuth: localStorage.fpAuth,
                deviceId: localStorage.uuid,                
                point: this.point,
                couponCode: this.couponCode,
                couponId: this.couponId,
                shippingAddressId: getLocationQueries()["shippingAddressId"],
                courierIds: this.courierIds,
                includeInsurances: this.includeInsurances,
                deliveryNotes: this.deliveryNotes, 
                orderId: this.orderId,
            },
            onSuccess: (response: BaseResponse<PaymentResponse>) => {
                process.env.REACT_APP_ENV === "production" ?
                    window.location.href = 'https://app.midtrans.com/snap/v3/redirection/'+response.data.token
                :
                    window.location.href = 'https://app.sandbox.midtrans.com/snap/v3/redirection/'+response.data.token
            },
            onFailure: (error: string) => {
                this.emit(() => {  
                  this.snackbarMessage = new ShowSnackbarModel({
                    message: error,
                    type: 'error'
                  });
                })
            }
        })
    }

    onSnackbarIsFinished() {
        this.errorMessage = null;
        this.emit(() => {});
    }
}