import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import ChannelChatPopup from "../../CommonComponents/UI/ChannelChatPopup/ChannelChatPopup";
import CouponViewModel from "./CouponViewModel";
import ButtonWidget, { ButtonWidgetType } from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import { Navigate  } from "react-router-dom";

import "./Coupon.css";
import ic_thefepi_f from "../../Assets/Images/Logo/ic_fepi_f.svg";
import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
import ViewController from "../../CommonComponents/Base/ViewController";
import ManualCouponInputWidget from "./Widgets/ManualCouponInputWidget";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";

export default class CouponScreen extends ViewController<{}, CouponViewModel> {
    constructor(props: {}) {
        super(props, new CouponViewModel());
        this.viewModel.loadPreloadingProps();
    }

    handleUseCoupon = (couponId: string, couponName: string) => {
        this.viewModel.handleUseCoupon(couponId, couponName);
    }

    Apply = () => {
        if(localStorage.point !== undefined)
        {
            localStorage.removeItem("point")
        }
        window.location.href = '/Checkout';
    }
    
    render() {
        const couponData = this.viewModel.couponData;
        const DeeplinkHeight = 46;
        const fixedHeight = 72;

        if (localStorage.getItem("selectedBagItem") === null) {
            return <Navigate  to='/'/>;
        }
        return(
            <Scaffold 
                isShowLoading={this.viewModel.isLoading}
                showSnackbar={this.viewModel.snackbarMessage}
                showErrorModel={this.viewModel.errorModel}
            >
                <div id="Coupon" className="container">
                    <p className="font14 margin0"><b>Coupon</b></p>
                    <SizedDiv height={12}/>
                    <ManualCouponInputWidget onClaimClick={() => {
                      this.viewModel.claimCoupon()
                    }}  code={this.viewModel.enteredCouponCode} onCodeChange={(code?: string) => this.viewModel.handleEnteredCouponCodeChange(code)}/>
         
                    <div className="row">
                        <div className="col">
                            <br></br>
                            <p className="font14">Available for this purchase</p>

                            {
                            couponData?.coupons.map((items,index) => (
                                <div className='box_white_cart' key={index}>
                                    <div className="row">
                                        <div className="col-3 text-center">
                                            <img width="50" src={global.ImgUrl+'shopable/imgCouponBig@3x.png'} alt="img_coupon"></img>
                                        </div> 
                                        <div className="col-1 text-start">
                                            
                                        </div>
                                        <div className="col-6 couponBody">
                                            <p className="couponTitle"><b>{items.title}</b></p>
                                            <p className="couponDesc">{items.subtitle}</p>
                                            <p className="couponDuration">{items.period}</p>
                                        </div>
                                        <div className="col-2 radio text-end">
                                            <input defaultChecked={localStorage.couponId === items.id && true} type="radio" name="radio_coupon" onChange={() => this.handleUseCoupon(items.id,items.title)}></input>
                                        </div>
                                        
                                    </div>
                                </div>
                            ))}
                            
                        </div>
                    </div>

                    <div id="CartTopGradient"  className="row" style={{bottom:`${(DeeplinkHeight+fixedHeight)-75}px`}}/>
                    <div className="row fixed">
                        <div className="col-2 text-center paddingtop20" data-bs-toggle="modal" data-bs-target="#ChatModal">
                            <img width="20" className="img-fluid" src={global.ImgUrl+'shopable/ic-detail-chat@2x.png'} alt="gambar1"></img>
                        </div>
                        <div className="col-10 text-center paddingtop10">
                            <ButtonWidget 
                                buttonType={ButtonWidgetType.primary} 
                                isLoading={this.viewModel.isLoadingButtonSubmit}
                                isEnabled={localStorage.getItem("selectedBagItem") === null ? false : true}
                                width="calc(100% - 6px)"
                                action={() => {
                                    this.Apply();
                                }} 
                            >
                                Apply
                            </ButtonWidget>
                        </div>
                    </div>

                    <div id="SpaceNavigationBackground" className="row" style={{opacity:`${this.viewModel.navBarTransparency}`}}></div>                
                    <div className="row floatingButton">
                        <button id="SpaceHomeButton" onClick={() => window.location.href = "/"}>
                            <img src={ic_thefepi_f} alt="" />
                        </button>
                        <button id="SpaceCartButton" onClick={() => {
                            window.location.href = "/Cart";
                        }}>
                            <img src={ic_cart} alt="" />
                            {
                                (this.viewModel.numberInCart ?? 0) > 0 && <div>{this.viewModel.numberInCart}</div>
                            }
                        </button>
                    </div>

                    <ChannelChatPopup />
                </div>
            </Scaffold>
        );
    }
} 