import "./AddressUpdater.css";

import ic_pinpoint_black from "../../Assets/Images/ic_pinpoint_black.svg";

import ic_pinpoint_white from "../../Assets/Images/ic_pinpoint_white.svg";
import ic_no_pinpoint_white from "../../Assets/Images/ic_no_pinpoint_white.svg";

import ViewController from "../../CommonComponents/Base/ViewController";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import { getLocationQueries, getRawLocationQueriesString, isValidEmail, isValidPhoneNumber } from "../../CommonComponents/Utils";
import AddressUpdaterViewModel from "./AddressUpdaterViewModel";
import ButtonWidget from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";

export default class AddressUpdaterViewController extends ViewController<{}, AddressUpdaterViewModel> {
    constructor(props: {}) {
        const { id, name, phone, email, addressDetail } = getLocationQueries();

        const addressLabel = window.localStorage.getItem('addressLabel');
        const latitude = window.localStorage.getItem('latitude');
        const longitude = window.localStorage.getItem('longitude');
        const isLoaded = window.localStorage.getItem('isLoaded');


        super(
            props, 
            new AddressUpdaterViewModel(
                id, name, phone, email, addressDetail, addressLabel!!, Number.parseFloat(latitude!!), Number.parseFloat(longitude!!)
            )
        );

        if (((isLoaded ?? "true") === "true") && id) {
            this.viewModel.getDetail();
        }

        window.localStorage.removeItem('addressLabel');
        window.localStorage.removeItem('latitude');
        window.localStorage.removeItem('isLoaded');
        window.localStorage.removeItem('longitude');
    }

    render() {
        return <div id="AddressUpdaterVC">
            <Scaffold 
                backgroundColor="var(--washoutGrey)"
                isShowLoading={this.viewModel.isLoading}
                topBar={
                    <>
                        <div className="FlexFillSpacing" style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "8px",
                            zIndex: "20",
                            fontSize: "16px",
                            fontWeight: "500"
                        }}>
                            Shipping Address
                        </div>
                    </>
                }
                showErrorModel={this.viewModel.errorMessage ? {
                    message: this.viewModel.errorMessage,
                    onRetryButtonTapped: () => this.viewModel.getDetail()
                } : undefined}
            >
                <div id="AddressUpdaterMainContainer">
                    <div>
                        <p>Recipient's Name</p>
                        <div id="AddressUpdaterTextFieldContainer">
                            <input 
                                type="text"
                                value={this.viewModel.name}
                                placeholder="Recipient's Name"
                                autoComplete="off"
                                style={{
                                    flexGrow: 1
                                }}
                                onChange={
                                    (event) => {
                                        this.viewModel.emit(
                                            () => this.viewModel.name = event.target.value
                                        );
                                    }
                                }
                            />
                        </div>

                        <p>Phone Number</p>
                        <div id="AddressUpdaterTextFieldContainer"  className={`${!isValidPhoneNumber(this.viewModel.phone) && this.viewModel.phone.length > 0 && 'error'}`}>
                            <input 
                               
                                type="text"
                                value={this.viewModel.phone}
                                placeholder="Phone Number"
                                autoComplete="off"
                                style={{
                                    flexGrow: 1
                                }}
                                onChange={
                                    (event) => {
                                        this.viewModel.emit(
                                            () => this.viewModel.phone = event.target.value
                                        );
                                    }
                                }
                            />
                        </div>

                        <p>Email</p>
                        <div id="AddressUpdaterTextFieldContainer" className={`${!isValidEmail(this.viewModel.email) && this.viewModel.email.length > 0 && 'error'}`}>
                            <input 
                                type="text"
                                value={this.viewModel.email}
                                placeholder="Email"
                                autoComplete="off"
                                style={{
                                    flexGrow: 1
                                }}
                                onChange={
                                    (event) => {
                                        this.viewModel.emit(
                                            () => this.viewModel.email = event.target.value
                                        );
                                    }
                                }
                            />
                        </div>
                        
                        <p>Address Pinpoint</p>
                        <div style={{height: "16px"}}/>
                        {
                            this.viewModel.checkIsPinpointAvailable() &&
                            <>
                                <div id="AddressUpdaterTextFieldContainer" style={{ padding: "16px", margin: "0" }}>
                                    <img src={ic_pinpoint_black} alt="" />
                                    <p style={{fontSize: "12px", marginLeft: "8px"}}>
                                        { this.viewModel.addressLabel }
                                    </p>
                                </div>
                                <div style={{height: "8px", flexShrink: 0}}/>
                            </>
                        }
                        <ButtonWidget 
                            backgroundColor="var(--purple)"
                            action={() => {
                                const id = this.viewModel.shippingAddressId;
                                const name = this.viewModel.name;
                                const phone = this.viewModel.phone;
                                const email = this.viewModel.email;
                                const addressDetail = this.viewModel.addressDetail;

                                window.location.href = `/Checkout/SearchLocation?${getRawLocationQueriesString()}&${id ? `id=${id}` : ""}&${name.length > 0 ? `name=${name}` : ""}&${phone.length > 0 ? `phone=${phone}` : ""}&${email.length > 0 ? `email=${email}`: ""}&${addressDetail.length > 0 ? `addressDetail=${addressDetail}` : ""}`;
                            }}
                        >
                            <img 
                                src={this.viewModel.checkIsPinpointAvailable() ? ic_pinpoint_white : ic_no_pinpoint_white} 
                                style={{marginRight: "4px"}} 
                                alt="" 
                            />
                            { this.viewModel.checkIsPinpointAvailable() ? "Change Pinpoint" : "Set Pinpoint" }
                        </ButtonWidget>
                        <div style={{height: "16px", flexShrink: 0}}/>
                        
                        <p>Address Detail</p>
                        <div id="AddressUpdaterTextFieldContainer" style={{
                            padding: "8px 24px",
                            fontSize: "14px"
                        }}>
                            <textarea
                                placeholder="Address Detail"
                                value={this.viewModel.addressDetail}
                                style={{
                                    width: "100%",
                                    height: "72px",
                                    resize: "none",
                                    border: "none"
                                }}
                                onChange={
                                    (event) => {
                                        this.viewModel.emit(
                                            () => this.viewModel.addressDetail = event.target.value
                                        );
                                    }
                                }
                            />
                        </div>
                    </div>
                    <div style={{position: 'fixed', left: 0, right: 0, display: 'flex', justifyContent: 'center', bottom: 0}}>
                            <div style={{backgroundColor: 'white',
     padding: "12px 24px",boxShadow: "0px 0px 22px rgb(0, 0, 0, 0.2)",maxWidth: 600, width: '100%'}}>
                        <ButtonWidget 
                            isEnabled={this.viewModel.checkIsAllFieldNotEmpty() && isValidEmail(this.viewModel.email) && isValidPhoneNumber(this.viewModel.phone)}
                            action={() => this.viewModel.submitUpdate(() => {
                                window.location.href = `/Checkout/AddressSelector?${getRawLocationQueriesString({exceptionKeys: ["id"]})}`;
                            })}
                            isLoading={this.viewModel.isSubmitting}
                        >
                            {
                                this.viewModel.shippingAddressId ?
                                "Update" : 
                                "Save"
                            }
                        </ButtonWidget>
                    </div>
                    </div>
                </div>
            </Scaffold>
        </div>
    }
}