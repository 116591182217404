import ViewController from "../../CommonComponents/Base/ViewController";
import "./SellerListsViewController.css";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import SellersListsViewModel from "./SellerListsViewModel";
import chevronLeftIcon from "../../Assets/Images/ic_chevron_left.svg";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import { LoadingWidget } from "../../CommonComponents/UI/LoadingWidget/LoadingWidget";
import { DivWithIntersectionObserver } from "../../CommonComponents/UI/DivWithIntersectionObserver/DivWithIntersectionObserver";

export default class SellerListsViewController extends ViewController<
  {},
  SellersListsViewModel
> {
  query?: string;

  constructor(props: any) {
    super(props, new SellersListsViewModel());
    
    const params = new URLSearchParams(window.location.search);
    this.query = params.get("query") || undefined;
    this.onEndReached = this.onEndReached.bind(this);
  }

  onEndReached() {
    this.viewModel.loadSellers();
  }

  renderHeader() {
    return (
      <div id="SellerListsHeaderContainer">
        <a href={`/Store/Search?query=${this.query}&defaultTabKey=contents`}>
          <img
            src={chevronLeftIcon}
            id="SellerListsHeaderBackIcon"
            alt="back to previous screen"
          />
        </a>
        <p id="SellerListsHeaderTitle">Accounts</p>
      </div>
    );
  }

  renderSellerList(
    sellers: {
      name?: string;
      id: string;
      imageUrl?: string;
    }[]
  ) {
    return sellers.map((seller) => (
      <a key={seller.name} id="SearchListItemsContainer" href={`/${seller.id}`}>
        <img src={seller.imageUrl} id="SearchListItemsImage" alt={``} />
        <p id="SearchListItemsLabel">{seller.name}</p>
      </a>
    ));
  }

  componentDidMount(): void {
    this.viewModel.loadSellers();
  }

  render() {
    return (
      <Scaffold topBar={this.renderHeader()}>
        <SizedDiv height={24} />
        {this.renderSellerList(this.viewModel.sellers)}
        {this.viewModel.totalSellers > this.viewModel.sellers.length && (
          <DivWithIntersectionObserver onVisibilityChanged={this.onEndReached} >
            <LoadingWidget />
          </DivWithIntersectionObserver>
        )}
      </Scaffold>
    );
  }
}
