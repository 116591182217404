import { ProductBadgeType } from "../CommonTypes";
import { requestAPI } from "./ApiRequest/APIRequest";
import BaseResponse from "./ApiRequest/BaseResponse";
import { Endpoints } from "./ApiRequest/Endpoints";

export type Size = {
    width: number,
    height: number
};

export function uploadCommonFile(file: File) {

  let formData = new FormData();
  formData.append("image", file, file.name);
  localStorage.getItem("fpAuth") &&
    formData.append(
      "fpAuth",
      localStorage.getItem("fpAuth")!!
    );

  return fetch(
    "https://manage.file.thefepi.com/app/UploadImage/common.php",
    {
      method: "POST",
      body: formData,
      headers: {
        // "X-API-Key":
        //   "Mnx6wGe8l3RFBj5fbemYtVd7jXnh6f2mqHDK0wvS29t1iB4RVygGg4ySjjiMoTDP",
      },
      redirect: "follow",
    }
  )
    .then((response) => response.json())
    .then((data) => data.data.imageUrl as string)
}

export function getScreenSize(): Size {
    const { innerWidth: width, innerHeight: height} = window;
    return {width, height};
}

export function getScreenSizeWithMaxWidth(): Size {
    let { innerWidth: width, innerHeight: height} = window;
    width = (width > 600 ? 600 : width);
    return {width, height};
}

export const isValidPhoneNumber = (number?: string) => {
  if (!number) return false;

  return /^\+?[0-9]\d{6,16}$/.test(number);
}

export const isValidEmail = (email?: string) => {
  if (!email) return false;
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)
}

export function productTypesForBadgeUrls(types: ProductBadgeType[] = [], map: {[key in ProductBadgeType]?: string  } ): string[] {
  return types.map(t => map[t]).filter(x => x) as string[];
}

export function mapBadgeTypes(values: {type: ProductBadgeType, url: string}[]) {
  return (values || []).reduce((acc, val) => {
              acc[val['type']] = val['url'];
              return acc;

            }, {} as {[key in ProductBadgeType]: string});
}

export function getPreloadProps(args: {
    onSuccess: (isCreator: boolean, creatorId: string, numberInCart: number, mappedProductBadgeUrls: {[key in ProductBadgeType]: string}) => void, 
    onFailure: (error: string) => void
}) {
    requestAPI(Endpoints.reusableProps, {
        onSuccess: (response: BaseResponse<{isCreator:boolean, creatorId:string, numberInCart:number, productBadgeImageUrls: {type: ProductBadgeType, url: string}[]}>) => {
            
            const mappedProductBadgeUrls = (response.data.productBadgeImageUrls || []).reduce((acc, val) => {
              acc[val['type']] = val['url'];
              return acc;

            }, {} as {[key in ProductBadgeType]: string});
            
            args.onSuccess(response.data.isCreator, response.data.creatorId, response.data.numberInCart, mappedProductBadgeUrls);
        },
        onFailure: (error: string) => {
            args.onFailure(error);
        }
    });
}

export function handleUnicode(str?: string | null): string {
    return JSON.parse(`"${(str ?? "").replaceAll(/\n/g, `<br>`)}"`).replaceAll(`<br>`, `\n`);
}

export function convertToRupiah(val?: number | null): string {
    let currencyNumberFormat = Intl.NumberFormat('en-US');
    let priceLabel = currencyNumberFormat.format(val ?? 0);

    return `Rp ${priceLabel}`
}

export function ValidateEmailTelp(val: string) {
    let re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ( re.test(val) || (!isNaN(+val) && val.length > 9)) {
        return true;
    }
    else{
        return false;
    }
}

export function normalizeString(text: string, maxString: number = 9999999) {
  let p = 0;

  const x = text.split(' ');

  const _x = x.reduce((acc, val) => {
    if (acc.length < maxString) {
      acc.push(val);
    }
    return acc
  }, [] as string[]);

  return _x
    .map((item, idx, items) => {
      console.log({item})
      const ret =
        p < maxString ? 
         
            item.split(/\\n|\n/).map((i, idx, els) => {
              return (
                <span>
                  {JSON.parse('"' + i + '"')}
                  {" "}
                  {idx !== els.length - 1 && <br />}
                </span>
              );
            }) : undefined;
      p += item.length;
      return ret;
    })
    .filter((x) => x);
}

export function standarizeThefepiLink(link: string): string {
    if (link.includes("me.thefepi.com") || link.includes("m.thefepi.com") || link.includes("thefepi://") || link.includes("thefepi-staging://")) {
        link = link.replace("https://me.thefepi.com/", "");
        link = link.replace("https://m.thefepi.com/", "");
        link = link.replace("https://dev.thefepi.com/mobile", "");
        link = link.replace("thefepi://", "");
        link = link.replace("thefepi-staging://", "");
        
        return `/${link}`;
    }

    return link;
}

export function getLocationQueries(): { [key: string] : string } {
    let urlQueryString = window.location.search;
    let result: { [key: string]: string } = {};

    urlQueryString.replaceAll("?", "").replaceAll("%20", " ").split("&").forEach((value) => {
        const keyAndValue = value.split("=");

        if (keyAndValue[0].length > 0) {
            result[keyAndValue[0]] = keyAndValue[1];
        }
    });
    

    return result;
}

export function getRawLocationQueriesString(args?: { exceptionKeys: string[] }): string {
    let queries = getLocationQueries();
    let queryString = "";

    args?.exceptionKeys.forEach((value) => delete queries[value] );

    Object.keys(queries).forEach((key) => {
        queryString += (queryString.length === 0) ? "" : "&";
        queryString += `${key}=${queries[key]}`;
    })
    
    return queryString;
}