import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import ChannelChatPopup from "../../CommonComponents/UI/ChannelChatPopup/ChannelChatPopup";
import CartViewModel from "./CartViewModel";
import CartItemsWidget from "./Widgets/CartItemsWidget";
import CartPriceWidget from "./Widgets/CartPriceWidget";
import CartRemovePopupWidget from "./Widgets/CartRemovePopupWidget";
import ButtonWidget, { ButtonWidgetType } from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";

import "./Cart.css";
import ic_thefepi_f from "../../Assets/Images/Logo/ic_fepi_f.svg";
import ic_x from "../../Assets/Images/ic_x.svg";
import ViewController from "../../CommonComponents/Base/ViewController";

export default class CartScreen extends ViewController<{}, CartViewModel> {
    constructor(props: {}) {
        super(props, new CartViewModel());
        this.viewModel.loadPreloadingProps();
    }

    removeItem = (bagItemId: string) => {
        this.viewModel.onRemoveItem(bagItemId);
    }

    closeModal = () => {
        document.body.classList.remove('modal-open');
        this.viewModel.onCloseModal();
    }

    closeModalBenefit = () => {
        window.location.href = "/Checkout";
    }

    handleCheckout = () => {
        this.viewModel.onHandleCheckout();
    }

    render() {
        const cartData = this.viewModel.cartData;
        const billingData = this.viewModel.billingData;
        const DeeplinkHeight = 46;
        const fixedHeight = 72;
        return(
            <Scaffold 
                isShowLoading={this.viewModel.isLoading}
                showSnackbar={this.viewModel.snackbarMessage}
                showErrorModel={this.viewModel.errorModel}
            >
                <div id="CartHome" className="container">
                    <CartItemsWidget 
                        cartData={cartData}
                        onPlusMinusClicked={(flag,bagtItemId,quantity) => this.viewModel.onAddToBag(flag,bagtItemId,quantity)}
                        onCheckClicked={(bagItemId) => this.viewModel.onChecked(bagItemId)}
                    />
                    <CartPriceWidget
                        cartData={cartData}
                        billingData={billingData}
                    />

                    <div id="ProductTopGradient" className="row" style={{bottom:`${(DeeplinkHeight+fixedHeight)-75}px`, width:"inherit"}}/>
                    <div className="row fixed">
                        <div className="col-2 text-center paddingtop20" data-bs-toggle="modal" data-bs-target="#ChatModal">
                            <img width="20" className="img-fluid" src={global.ImgUrl+'shopable/ic-detail-chat@2x.png'} alt="gambar1"></img>
                        </div>
                        <div className="col-10 text-center paddingtop10" id="CheckoutButtonContainer">
                            <ButtonWidget 
                                buttonType={ButtonWidgetType.primary} 
                                isLoading={this.viewModel.isLoadingButtonSubmit ? true : false}
                                isEnabled={this.viewModel.isEnableBtn ? true : false}
                                width="calc(100% - 6px)"
                                action={() => {
                                    this.handleCheckout();
                                }} 
                            >
                                Checkout
                            </ButtonWidget>
                        </div>
                    </div>

                    <div id="SpaceNavigationBackground" className="row" style={{opacity:`${this.viewModel.navBarTransparency}`}}></div>                
                    <div className="row floatingButton" style={{display: "flex", alignItems: "center"}}>
                        <button id="SpaceHomeButton" onClick={() => window.location.href = "/"}>
                            <img src={ic_thefepi_f} alt="" />
                        </button>
                    </div>
                </div>

                <CartRemovePopupWidget
                    showModal={this.viewModel.showModal}
                    Message={this.viewModel.Message}
                    closeModal={() => {
                        this.closeModal();
                    }} 
                    removeItem={() => {
                        this.viewModel.onRemoveItem(this.viewModel.bag_item_id)
                    }} 
                />

                <div className={this.viewModel.shouldShowErrorModal ? "modal-backdrop fade show" : ""}></div>
                <div className={this.viewModel.shouldShowErrorModal ? "modal fade show" : "modal fade"} style={this.viewModel.showModalBenefit ? {display:'block'} : {display:'none'}} id="BenefitModal" aria-labelledby="ErrorModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="row" style={{marginBottom: 18}}>
                              <div style={{display: 'flex'}}>
                              <button style={{marginLeft: 'auto'}} onClick={() => {
                                this.viewModel.onCloseErrorModal()
                              }}>
                              <img alt="" src={ic_x} /></button></div>                                
                            </div>
                            <div className="row">
                               
                                <div className="col">
                                    <span className="ErrorModalTitle">Continue in app</span>
                                    <span className="ErrorModalSubtitle">{this.viewModel.errorMessage}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-center">
                                    
                                    <div style={{marginBottom: 12}}>
                                      <ButtonWidget action={() => {
                                      window.open(this.viewModel.getAppStoreLink(), "_blank")
                                      }}>Open App</ButtonWidget>
                                    </div>
                                    <ButtonWidget action={() => {
                                      window.location.href = "/Checkout"
                                    }} buttonType={ButtonWidgetType.secondary}>Continue here without promo</ButtonWidget>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={this.viewModel.showModalBenefit ? "modal-backdrop fade show" : ""}></div>
                <div className={this.viewModel.showModalBenefit ? "modal fade show" : "modal fade"} style={this.viewModel.showModalBenefit ? {display:'block'} : {display:'none'}} id="BenefitModal" aria-labelledby="ErrorModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="row">
                                <div className="col">
                                    <span>Get more benefit such as point and coupon if you are logged in</span>
                                    <br></br><br></br>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-center">
                                    <button onClick={this.closeModalBenefit} className="btn btn-secondary">Continue Checkout</button>
                                </div>
                                <div className="col text-center">
                                    <a href="/Login"><button className="btn btn-primary">Sign In</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                

                <ChannelChatPopup />
            </Scaffold>
        );
    }

    private handleScrolling() {
        const productImageContainerElement = document.querySelector("#ProductImageContainer");
        const spaceTopContainerRect = productImageContainerElement?.getBoundingClientRect();
        let transparencyValue = (spaceTopContainerRect?.y ?? 0) / -((this.state.width * 3 / 2) + 16)
        transparencyValue = transparencyValue > 1 ? 1 : transparencyValue;

        const navBar = document.querySelector("#SpaceNavigationBackground");
        this.viewModel.changeNavBarTransparency(transparencyValue);
        if (navBar) {
            (navBar as any).style.opacity = transparencyValue;
        }

    }
} 