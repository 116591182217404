import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate , Outlet } from "react-router-dom";
import { ContentExplorerDiscoverScreen, ContentExplorerFollowingScreen } from "../Content/ContentDisplay/Explorer/ContentExplorerScreen";
import CreatorSpaceScreen from "../Content/CreatorSpace/CreatorSpaceScreen";
import CreatorRegistrationScreen from "../UserPlatform/CreatorRegistration/CreatorRegistrationScreen";
import CreatorRegistrationSuccessScreen from "../UserPlatform/CreatorRegistration/CreatorRegistrationSuccessScreen";
import CartScreen from "../Store/Cart/CartScreen";
import PointViewController from "../Store/Point/PointViewController";
import CouponScreen from "../Store/Coupon/CouponScreen";
import ContentEventDetailScreen from "../Store/ContentEventDetail/ContentEventDetailViewController";
import Login from "../UserPlatform/Login/LoginScreen";
import MyPageScreen from "../UserPlatform/MyPage/MyPageScreen";
import LoginSsoScreen from "../UserPlatform/Login/LoginSsoScreen";
import ForgotPasswordScreen from "../UserPlatform/ForgotPassword/ForgotPasswordScreen";
import OrderReceiptScreen from "../Store/OrderReceipt/OrderReceiptScreen";
import ProductDetailScreen from "../Store/ProductDetail/ProductDetailScreen";
import RegisterScreen from "../UserPlatform/Register/RegisterScreen";
import RegisterVerificationScreen from "../UserPlatform/Register/RegisterVerificationScreen";
import RegisterPasswordScreen from "../UserPlatform/Register/RegisterPasswordScreen";
import RegisterProfileScreen from "../UserPlatform/Register/RegisterProfileScreen";
import ContentDisplayScreen from "../Content/ContentDisplay/Display/ContentDisplayScreen";
import JastipScreen from "../Store/Jastip/JastipScreen";
import ChannelSinglePageScreen from "../Store/Channel/ChannelSinglePageScreen";
import CustomerLevelScreen from "../Store/CustomerLevel/CustomerLevelScreen";
import PhotoGuideScreen from "../Store/PhotoGuide/PhotoGuideScreen";
import PrivacyPolicyScreen from "../Store/PrivacyPolicy/PrivacyPolicyScreen";
import TermServiceScreen from "../Store/TermService/TermServiceScreen";
import CreatorLevelScreen from "../Store/CreatorLevel/CreatorLevelScreen";
import CreatorRecruitingScreen from "../Store/CreatorRecruiting/CreatorRecruitingScreen";
import PaymentScreen from "../Store/Payment/PaymentScreen";
import SizeChartScreen from "../Store/SizeChart/SizeChartScreen";
import StoreBrandHomeScreen from "../Store/BrandHome/StoreBrandHomeScreen";
import StoreBrandProductsScreen from "../Store/BrandProducts/StoreBrandProductsScreen";
import CuratedProductsScreen from "../Store/CuratedProducts/CuratedProductsScreen";
import StoreMainHomeScreen from "../Store/MainHome/StoreMainHomeViewController";
import OrderAndDeliveryViewController from "../OrderAndPayment/OrderAndDelivery/OrderAndDeliveryViewController";
import ProductGroupMainScreen from "../Store/ProductGroupMain/ProductGroupMainViewController";
import SearchProductsViewController from "../Store/SearchProducts/OmniSearchViewController";
import EditProfileViewController from "../UserPlatform/EditProfile/EditProfileViewController";
import OrderDetailViewController from "../OrderAndPayment/OrderDetail/OrderDetailViewController";
import ShowCaseDetailViewController from "../ShowCase/Detail/ShowCaseDetailViewController";
import { ShowCaseHomeViewController } from "../ShowCase/Home/ShowCaseHomeViewController";
import LocationSearchViewController from "../Store/LocationSearch/LocationSearchViewController";
import AddressSelectorViewController from "../Store/AddressSelector/AddressSelectorViewController";
import AddressUpdaterViewController from "../Store/AddressUpdater/AddressUpdaterViewController";
import CourierSelectorViewController from "../Store/CourierSelector/CourierSelectorViewController";
import SellerListsViewController from "../Store/SellerList/SellerListsViewController";
import CheckoutBridgeScreen from "../Store/Checkout/CheckoutScreen";
import CreatorIntroductionViewController from "../Content/CreatorIntroduction/CreatorIntroductionViewController";
import BrandListsViewController from "../Store/BrandList/BrandListsViewController";
import ToReviewViewController from "../Store/ToReview/ToReviewViewController";
import ReviewListViewController from "../Store/ReviewList/ReviewListViewController";

export class FepiRouter extends React.Component {
    render() {
        return(
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="/Brand/:id" element={<StoreBrandProductsScreen />} />
                        <Route path="/Cart" element={<CartScreen />} />
                        <Route path="/ChannelSinglePage" element={<ChannelSinglePageScreen />} />
                        <Route path="/Checkout/AddressSelector" element={<AddressSelectorViewController />} />
                        <Route path="/Checkout/AddressUpdater" element={<AddressUpdaterViewController />} />
                        <Route path="/Checkout/CourierSelector" element={<CourierSelectorViewController />} />
                        <Route path="/Checkout/SearchLocation" element={<LocationSearchViewController />} />
                        <Route path="/Checkout" element={<CheckoutBridgeScreen />} />
                        <Route path="/Content/:id" element={<ContentDisplayScreen />} />
                        <Route path="/ContentEventDetail/:contentId" element={<ContentEventDetailScreen />} />
                        <Route path="/Event/:contentId" element={<ContentEventDetailScreen />} />
                        <Route path="/Coupon" element={<CouponScreen />} />
                        <Route path="/CreatorIntroduction" element={<CreatorIntroductionViewController />} />
                        <Route path="/CreatorLevel" element={<CreatorLevelScreen />} />
                        <Route path="/CreatorRecruiting" element={<CreatorRecruitingScreen />} />
                        <Route path="/CreatorRegistration/*" element={<CreatorRegistrationScreen />} />
                        <Route path="/CreatorRegistrationSuccess/*" element={<CreatorRegistrationSuccessScreen />} />
                        <Route path="/CustomerLevel" element={<CustomerLevelScreen />} />
                        <Route path="/Discover" element={<ContentExplorerDiscoverScreen />} />
                        <Route path="/EditProfile" element={<EditProfileViewController />} />
                        <Route path="/Following" element={<ContentExplorerFollowingScreen />} />
                        <Route path="/ForgotPassword" element={<ForgotPasswordScreen />} />
                         <Route path="/ToReview" element={<ToReviewViewController />} />
                        <Route path="/Jastip" element={<JastipScreen />} />
                        <Route path="/Login" element={<Login />} />
                        <Route path="/LoginSso" element={<LoginSsoScreen />} />
                        <Route path="/Order/*" element={<OrderReceiptScreen />} />
                        <Route path="/MyPage" element={<MyPageScreen />} />
                        <Route path="/OrderAndDelivery" element={<OrderAndDeliveryViewController />} />
                        <Route path="/OrderDetail/:orderId" element={<OrderDetailViewController />} />
                        <Route path="/Order/Finish/*" element={<OrderReceiptScreen />} />
                        <Route path="/OrderReceipt" element={<OrderReceiptScreen />} />
                        <Route path="/Payment" element={<PaymentScreen />} />
                        <Route path="/PhotoGuide" element={<PhotoGuideScreen />} />
                        <Route path="/Point" element={<PointViewController />} />
                        
                        <Route path="/PrivacyPolicy" element={<Outlet />}>          
                          <Route index element={<PrivacyPolicyScreen />}/>                
                          <Route path=":lang" element={<PrivacyPolicyScreen />} />
                        </Route>
                        <Route path="/Product/:productId" element={<ProductDetailScreen />} />
                        <Route path="/product/detail/:productId" element={<ProductDetailScreen />} />
                        <Route path="/Product/:productId/Reviews" element={<ReviewListViewController />} />
                        <Route path="/Sellers" element={<SellerListsViewController />} />                        
                        <Route path="/Brands" element={<BrandListsViewController />} />
                        <Route path="/Register" element={<RegisterScreen />} />
                        <Route path="/RegisterPassword" element={<RegisterPasswordScreen />} />
                        <Route path="/RegisterProfile" element={<RegisterProfileScreen />} />
                        <Route path="/RegisterVerification" element={<RegisterVerificationScreen />} />
                        <Route path="/ShowCase" element={<ShowCaseHomeViewController />} />
                        <Route path="/ShowCase/Detail" element={<ShowCaseDetailViewController />} />
                        <Route path="/Store/Brands" element={<StoreBrandHomeScreen />} />
                        <Route path="/Store/Products" element={<CuratedProductsScreen />} />
                        <Route path="/Store/Products/:categoryId" element={<CuratedProductsScreen />} />
                        <Route path="/Store/Search" element={<SearchProductsViewController />} />
                        <Route path="/SizeChart/:id" element={<SizeChartScreen />} />
                        <Route path="/SizeChart/:brandId/:flag" element={<SizeChartScreen />} />
                        <Route path="/Store/:groupId/:categoryId" element={<ProductGroupMainScreen />} />
                        <Route path="/Store/:groupId" element={<StoreMainHomeScreen />} />
                        <Route path="/Store" element={<StoreMainHomeScreen />} />
                        <Route path="/TermService" element={<Outlet />}>                        
                          <Route index element={<TermServiceScreen />}/>
                          <Route path=":lang" element={<TermServiceScreen />}/>
                        </Route>
                        <Route path="/:id" element={<CreatorSpaceScreen />} />
                        <Route path="/" element={<Navigate to="/Store" />} />
                        <Route path="/*" element={<Navigate to="/Store" />} />
                    </Routes>
                </div>
            </Router>
        );
    }
}