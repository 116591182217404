import React from 'react';

const url = window.location.href;
const lang = url.substring(url.lastIndexOf('/') + 1);

class PrivacyPolicyScreen extends React.Component {
    render() {
        return (
            <div className="container" id="TermService">
                <ul className='list-inline' style={{ position: 'absolute', top: '10px', right: '10px' }}>
                    <li className='list-inline-item'>
                        {
                            lang === 'en' || lang === 'PrivacyPolicy' ?
                                <a href="/PrivacyPolicy/en" style={{ color: 'black', textDecoration: 'none' }}><p style={{ fontSize: '15px' }}><b>en</b></p></a>
                                :
                                <a href="/PrivacyPolicy/en" style={{ color: 'black', textDecoration: 'none' }}><p style={{ fontSize: '15px', color: '#cecece' }}>en</p></a>
                        }

                    </li>
                    <li className='list-inline-item'>
                        <p>|</p>
                    </li>
                    <li className='list-inline-item'>
                        {
                            lang === 'id' ?
                                <a href="/PrivacyPolicy/id" style={{ color: 'black', textDecoration: 'none' }}><p style={{ fontSize: '15px' }}><b>id</b></p></a>
                                :
                                <a href="/PrivacyPolicy/id" style={{ color: 'black', textDecoration: 'none' }}><p style={{ fontSize: '15px', color: '#cecece' }}>id</p></a>
                        }
                    </li>
                </ul>
                {
                    lang === 'id' ?
                        <>
                            <div className="row">
                                <div className="col text-left">
                                    <br></br>
                                    <h4 style={{ borderBottom: 'solid black 3px', fontSize: '20px' }}>Kebijakan privacy<br></br><br></br></h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-left">
                                    <p>KEBIJAKAN PRIVASI</p>
                                    <p>Adanya Kebijakan Privasi ini adalah komitmen nyata dari PT. Ide Picup Group untuk menghargai dan melindungi setiap data atau informasi pribadi&nbsp;Pengguna Aplikasi thefepi atau www.thefepi.co.id, Aplikasi-Aplikasi turunannya, serta aplikasi gawai PT. Ide Picup Group (selanjutnya disebut sebagai &quot;Aplikasi&quot;).</p>
                                    <p>Kebijakan Privasi ini (beserta syarat-syarat penggunaan dari Aplikasi PT. Ide Picup Group sebagaimana tercantum dalam Syarat &amp; Ketentuan dan informasi lain yang tercantum di Aplikasi) menetapkan dasar atas perolehan, pengumpulan, pengolahan, penganalisisan, penampilan, pengiriman, pembukaan, penyimpanan, perubahan, penghapusan dan/atau segala bentuk pengelolaan yang terkait dengan data atau informasi yang mengidentifikasikan atau dapat digunakan untuk mengidentifikasi Pengguna yang Pengguna berikan kepada PT. Ide Picup Group atau yang PT. Ide Picup Group kumpulkan dari Pengguna maupun pihak ketiga (selanjutnya disebut sebagai &quot;Data Pribadi&quot;).</p>
                                    <p>Dengan Mengklik &quot;Daftar&quot; (Register) atau pernyataan serupa pada halaman pendaftaran, pengguna menyatakan bahwa data pribadi yang diberikan merupakan data yang benar dan sah, Pengguna mengakui bahwa telah diberitahu dan memahami Kebijakan Privasi ini dan memberikan persetujuan kepada PT. Ide Picup Group untuk mendapatkan, mengumpulkan, memproses, menganalisis, menampilkan, mengirimkan, membuka, menyimpan, mengubah, menghapus, mengelola dan/atau menggunakan data tersebut untuk tujuan sebagaimana dinyatakan dalam Kebijakan Privasi.</p>

                                    <h4>A. Perolehan dan Pengumpulan Data Pribadi Pengguna</h4>
                                    <p>Data pengguna dikumpulkan oleh PT Ide Picup Group dengan tujuan untuk memproses tansaksi yang dilakukan oleh pengguna, mengelola dan melancarkan proses penggunaan Aplikasi, serta tujuan lainnya yang diizinkan oleh peraturan perundang-undangan yang berlaku. Data Pengguna yang dikumpulkan adalah sebagai berikut:</p>
                                    <ol>
                                        <li>
                                            <p>Data yang diserahkan secara mandiri oleh Pengguna, termasuk namun tidak terbatas pada data yang diserahkan pada saat Pengguna:</p>
                                            <ol>
                                                <li>
                                                    <p>membuat atau memperbarui akun PT. Ide Picup Group, termasuk diantaranya nama pengguna (username), alamat email, nomor telepon, password, alamat, foto, dan informasi lainnya yang dapat mengidentifikasi Pengguna;</p>
                                                </li>
                                                <li>
                                                    <p>menghubungi PT. Ide Picup Group, termasuk melalui layanan pelanggan (customer service);</p>
                                                </li>
                                                <li>
                                                    <p>mengisi survei yang dikirimkan oleh PT. Ide Picup Group atau pihak lain yang ditunjuk secara resmi untuk mewakili PT. Ide Picup Group;</p>
                                                </li>
                                                <li>
                                                    <p>melakukan interaksi dengan Pengguna lainnya melalui fitur pesan, diskusi produk, ulasan, rating, Pusat Resolusi (sebagaimana didefinisikan dalam Syarat dan Ketentuan) dan fitur interaktif (baik satu atau dua arah) lainnya yang terdapat dalam Aplikasi;</p>
                                                </li>
                                                <li>
                                                    <p>mempergunakan layanan-layanan pada Aplikasi, termasuk data transaksi yang detil, diantaranya jenis, jumlah dan/atau keterangan dari produk atau layanan yang dibeli, alamat pengiriman, kanal pembayaran yang digunakan, jumlah transaksi, tanggal dan waktu transaksi, serta detil transaksi lainnya;</p>
                                                </li>
                                                <li>
                                                    <p>mengisi data-data pembayaran pada saat Pengguna melakukan aktivitas transaksi pembayaran melalui Aplikasi, termasuk namun tidak terbatas pada data rekening bank, kartu kredit, virtual account, instant payment, internet banking, gerai ritel; dan/atau</p>
                                                </li>
                                                <li>
                                                    <p>menggunakan fitur pada Aplikasi yang membutuhkan izin akses ke data yang relevan yang tersimpan dalam perangkat Pengguna.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p>Data yang terekam pada saat Pengguna mempergunakan Aplikasi, termasuk namun tidak terbatas pada:</p>
                                            <ol>
                                                <li>
                                                    <p>data lokasi riil atau perkiraannya seperti alamat IP, lokasi Wi-Fi dan geo-location;</p>
                                                </li>
                                                <li>
                                                    <p>data berupa waktu dari setiap aktivitas Pengguna sehubungan dengan penggunaan Aplikasi, termasuk waktu pendaftaran, login dan transaksi;</p>
                                                </li>
                                                <li>
                                                    <p>data penggunaan atau preferensi Pengguna, diantaranya interaksi Pengguna dalam menggunakan Aplikasi, pilihan yang disimpan, serta pengaturan yang dipilih. Data tersebut diperoleh menggunakan cookies, pixel tags, dan teknologi serupa yang menciptakan dan mempertahankan pengenal unik;</p>
                                                </li>
                                                <li>
                                                    <p>data perangkat, diantaranya jenis perangkat yang digunakan untuk mengakses Aplikasi, termasuk model perangkat keras, sistem operasi dan versinya, perangkat lunak, nomor IMEI, nama file dan versinya, pilihan bahasa, pengenal perangkat unik, pengenal iklan, nomor seri, informasi gerakan perangkat, dan/atau informasi jaringan seluler; dan/atau</p>
                                                </li>
                                                <li>
                                                    <p>data catatan (log), diantaranya catatan pada server yang menerima data seperti alamat IP perangkat, tanggal dan waktu akses, fitur aplikasi atau laman yang dilihat, proses kerja aplikasi dan aktivitas sistem lainnya, jenis peramban (browser), dan/atau Aplikasi atau layanan pihak ketiga yang Pengguna gunakan sebelum berinteraksi dengan Aplikasi.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p>Data yang diperoleh dari sumber lain, termasuk namun tidak terbatas pada:</p>
                                            <ol>
                                                <li>
                                                    <p>data berupa geo-location (GPS) dari mitra usaha PT. Ide Picup Group yang turut membantu PT. Ide Picup Group dalam mengembangkan dan menyajikan layanan-layanan dalam Aplikasi kepada Pengguna, antara lain mitra penyedia layanan pembayaran, logistik atau kurir, infrastruktur Aplikasi, dan mitra- mitra lainnya.</p>
                                                </li>
                                                <li>
                                                    <p>data berupa email, nomor telepon, nama, gender, dan/atau tanggal lahir dari mitra usaha PT. Ide Picup Group tempat Pengguna membuat atau mengakses akun PT. Ide Picup Group, seperti layanan media sosial, atau Aplikasi/aplikasi yang menggunakan application programming interface (API) PT. Ide Picup Group atau yang digunakan PT. Ide Picup Group;</p>
                                                </li>
                                                <li>
                                                    <p>data dari penyedia layanan finansial, termasuk namun tidak terbatas pada lembaga atau biro pemeringkat kredit atau Lembaga Pengelola Informasi Perkreditan (LPIP);</p>
                                                </li>
                                                <li>
                                                    <p>data dari penyedia layanan finansial (apabila Pengguna menggunakan fitur spesifik seperti mengajukan pinjaman melalui Aplikasi/Aplikasi PT. Ide Picup Group); dan/atau</p>
                                                </li>
                                                <li>
                                                    <p>data berupa email dari penyedia layanan pemasaran.</p>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                    <p>PT. Ide Picup Group dapat menggabungkan data yang diperoleh dari sumber tersebut dengan data lain yang dimilikinya.</p>

                                    <h4>B. Penggunaan Data Pribadi</h4>
                                    <p>Seperti yang disebutkan sebelumnya, data pribadi yang telah diperoleh dan dikumpulkan dari para pengguna dapat dipakai oleh PT. Ide Picup Group untuk bebrapahal, yaitu:</p>

                                    <ol>
                                        <li>
                                            <p>Memproses segala bentuk permintaan, aktivitas maupun transaksi yang dilakukan oleh Pengguna melalui Aplikasi, termasuk untuk keperluan pengiriman produk kepada Pengguna.</p>
                                        </li>
                                        <li>
                                            <p>Penyediaan fitur-fitur untuk memberikan, mewujudkan, memelihara dan memperbaiki produk dan layanan kami, termasuk:</p>
                                            <ol>
                                                <li>
                                                    <p>menawarkan, memperoleh, menyediakan, atau memfasilitasi layanan marketplace seperti laman beranda, penelusuran, pencarian, rekomendasi produk, PT. Ide Picup Group Feed, asuransi, pembiayaan, pinjaman, maupun produk-produk lainnya melalui Aplikasi;</p>
                                                </li>
                                                <li>
                                                    <p>memungkinkan fitur untuk mempribadikan (personalised) akun PT. Ide Picup Group Pengguna, seperti Keranjang Belanja, Wishlist dan Toko Favorit; dan/atau</p>
                                                </li>
                                                <li>
                                                    <p>melakukan kegiatan internal yang diperlukan untuk menyediakan layanan pada Aplikasi/aplikasi PT. Ide Picup Group, seperti pemecahan masalah software, bug, permasalahan operasional, melakukan analisis data, pengujian, dan penelitian, dan untuk memantau dan menganalisis kecenderungan penggunaan dan aktivitas.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p>Membantu Pengguna pada saat berkomunikasi dengan Layanan Pelanggan PT. Ide Picup Group, diantaranya untuk:</p>
                                            <ol>
                                                <li>
                                                    <p>memeriksa dan mengatasi permasalahan Pengguna;</p>
                                                </li>
                                                <li>
                                                    <p>mengarahkan pertanyaan Pengguna kepada petugas layanan pelanggan yang tepat untuk mengatasi permasalahan;&nbsp;</p>
                                                </li>
                                                <li>
                                                    <p>mengawasi dan memperbaiki tanggapan layanan pelanggan PT. Ide Picup Group;</p>
                                                </li>
                                                <li>
                                                    <p>menghubungi Pengguna melalui email, surat, telepon, fax, dan metode komunikasi lainnya, termasuk namun tidak terbatas, untuk membantu dan/atau menyelesaikan proses transaksi maupun proses penyelesaian kendala, serta menyampaikan berita atau notifikasi lainnya sehubungan dengan perlindungan Data Pribadi Pengguna oleh PT. Ide Picup Group, termasuk kegagalan perlindungan Data Pribadi Pengguna;</p>
                                                </li>
                                                <li>
                                                    <p>menggunakan informasi yang diperoleh dari Pengguna untuk tujuan penelitian, analisis, pengembangan dan pengujian produk guna meningkatkan keamanan layanan-layanan pada Aplikasi, serta mengembangkan fitur dan produk baru; dan</p>
                                                </li>
                                                <li>
                                                    <p>menginformasikan kepada Pengguna terkait produk, layanan, promosi, studi, survei, berita, perkembangan terbaru, acara dan informasi lainnya, baik melalui Aplikasi maupun melalui media lainnya. PT. Ide Picup Group juga dapat menggunakan informasi tersebut untuk mempromosikan dan memproses kontes dan undian, memberikan hadiah, serta menyajikan iklan dan konten yang relevan tentang layanan PT. Ide Picup Group dan mitra usahanya.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p>Melakukan&nbsp;monitoring&nbsp;ataupun investigasi terhadap transaksi-transaksi mencurigakan atau transaksi yang terindikasi mengandung unsur kecurangan atau pelanggaran terhadap Syarat dan Ketentuan atau ketentuan hukum yang berlaku, serta melakukan tindakan-tindakan yang diperlukan sebagai tindak lanjut dari hasil monitoring atau investigasi transaksi tersebut.</p>
                                        </li>
                                        <li>
                                            <p>Dalam keadaan tertentu, PT. Ide Picup Group mungkin perlu untuk menggunakan ataupun mengungkapkan Data Pribadi Pengguna untuk tujuan penegakan hukum atau untuk pemenuhan persyaratan dan kewajiban peraturan perundang-undangan yang berlaku, termasuk dalam hal terjadinya sengketa atau proses hukum antara Pengguna dan PT. Ide Picup Group, atau dugaan tindak pidana seperti penipuan atau pencurian data.</p>
                                        </li>
                                        <li>
                                            <p>Memfasilitasi transaksi penggabungan, penjualan aset perusahaan, konsolidasi atau restrukturisasi, pembiayaan atau akuisisi yang melibatkan PT. Ide Picup Group.</p>
                                        </li>
                                    </ol>
                                    <h4>C. Pengungkapan Data Pribadi Pengguna</h4>
                                    <ol>
                                        <li>
                                            <p>Komitmen PT. Ide Picup Group adalah menjaga kerahasiaan dari data pribadi para penggunanya dan menjamin tidak adanya pengungkapan, penjualan, pemindahtanganan, pendistribusian dan/atau peminjaman Data Pribadi Pengguna kepada pihak ketiga lainnya, tanpa persetujuan Pengguna, kecuali dalam hal berikut:</p>
                                            <ol>
                                                <li>
                                                    <p>dibutuhkan adanya pengungkapan Data Pribadi Pengguna kepada mitra atau pihak ketiga lain yang membantu PT. Ide Picup Group dalam menyajikan layanan yang tersedia atau layanan yang di kemudian hari akan tersedia pada Aplikasi dan memproses segala bentuk aktivitas Pengguna dalam Aplikasi, termasuk memproses transaksi, verifikasi pembayaran, promosi, dan pengiriman produk.</p>
                                                </li>
                                                <li>
                                                    <p>PT. Ide Picup Group dapat menyediakan Data Pribadi Pengguna kepada mitra usaha sesuai dengan persetujuan Pengguna untuk menggunakan layanan mitra usaha, termasuk diantaranya aplikasi atau Aplikasi lain yang telah saling mengintegrasikan API atau layanannya, atau mitra usaha yang mana PT. Ide Picup Group telah bekerjasama untuk menyelenggarakan promosi, perlombaan, atau layanan khusus yang tersedia pada Aplikasi.</p>
                                                </li>
                                                <li>
                                                    <p>PT. Ide Picup Group dapat menyediakan Data Pribadi Pengguna kepada pihak ketiga yang menggunakan dan mengintegrasikan API publik yang disediakan oleh PT. Ide Picup Group (termasuk namun tidak terbatas pada penyedia layanan mitra usaha PT. Ide Picup Group) dengan aplikasi atau Aplikasi yang dioperasikannya untuk kepentingan penyelesaian transaksi antara Pembeli dan Penjual pada Aplikasi atau tujuan penggunaan Data Pribadi lainnya yang telah disebutkan pada Bagian B Kebijakan Privasi ini.&nbsp;</p>
                                                </li>
                                                <li>
                                                    <p>dibutuhkan adanya komunikasi antara mitra usaha PT. Ide Picup Group (seperti penyedia logistik, pembayaran, dan penyedia fitur atau fasilitas layanan lainnya) dengan Pengguna dalam hal penyelesaian kendala maupun hal-hal lainnya.</p>
                                                </li>
                                                <li>
                                                    <p>PT. Ide Picup Group dapat menyediakan Data Pribadi Pengguna kepada vendor, konsultan, mitra pemasaran, firma/lembaga riset, atau penyedia layanan sejenis dalam rangka kegiatan pemasaran yang dilakukan oleh pihak ketiga, peningkatan dan pemeliharan kualitas layanan PT. Ide Picup Group, serta kegiatan publikasi lainnya.</p>
                                                </li>
                                                <li>
                                                    <p>Pengguna menghubungi PT. Ide Picup Group melalui media publik seperti blog, media sosial, dan fitur-fitur tertentu pada Aplikasi, yang merupakan komunikasi antara Pengguna dan PT. Ide Picup Group dapat dilihat dan diketahui oleh masyarakat umum</p>
                                                </li>
                                                <li>
                                                    <p>PT. Ide Picup Group dapat membagikan Data Pribadi Pengguna kepada anak perusahaan dan afiliasinya untuk membantu memberikan layanan atau melakukan pengolahan data untuk dan atas nama PT. Ide Picup Group.</p>
                                                </li>
                                                <li>
                                                    <p>PT. Ide Picup Group dapat mengungkapkan dan/atau memberikan Data Pribadi Pengguna kepada pihak-pihak yang berkepentingan dalam rangka pelaksanaan analisis kelayakan kredit Pengguna.&nbsp;</p>
                                                </li>
                                                <li>
                                                    <p>PT. Ide Picup Group mengungkapkan Data Pribadi Pengguna dalam upaya mematuhi kewajiban hukum dan/atau adanya permintaan yang sah dari aparat penegak hukum atau instansi penyelenggara negara yang berwenang.</p>
                                                </li>
                                                <li>
                                                    <p>PT. Ide Picup Group dapat menyediakan Data Pribadi Pengguna kepada mitra usaha sesuai dengan persetujuan Pengguna untuk menggunakan layanan mitra usaha, termasuk diantaranya aplikasi Facebook, atau mitra usaha yang mana PT. Ide Picup Group telah bekerjasama untuk menyelenggarakan promosi, perlombaan, atau layanan khusus yang tersedia pada aplikasi dan situs web The Fepi.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p>Sehubungan dengan pengungkapan Data Pribadi Pengguna sebagaimana dijelaskan di atas, PT. Ide Picup Group akan/mungkin perlu mengungkapkan Data Pribadi Pengguna kepada penyedia layanan pihak ketiga, agen dan/atau afiliasi atau perusahaan terkait PT. Ide Picup Group, dan/atau pihak ketiga lainnya yang berlokasi di luar wilayah Indonesia. Meskipun demikian, penyedia layanan pihak ketiga, agen dan/atau afiliasi atau perusahaan terkait dan/atau pihak ketiga lainnya tersebut hanya akan mengelola dan/atau memanfaatkan Data Pribadi Pengguna sehubungan dengan satu atau lebih tujuan sebagaimana diatur dalam Kebijakan Privasi ini dan sesuai dengan ketentuan peraturan perundangan-undangan yang berlaku.</p>
                                        </li>
                                    </ol>
                                    <h4>D. Cookies</h4>
                                    <ol>
                                        <li>
                                            <p>Cookies adalah file kecil yang secara otomatis akan mengambil tempat di dalam perangkat Pengguna yang menjalankan fungsi dalam menyimpan preferensi maupun konfigurasi Pengguna selama mengunjungi suatu Aplikasi.</p>
                                        </li>
                                        <li>
                                            <p>Cookies tersebut tidak diperuntukkan untuk digunakan pada saat melakukan akses data lain yang Pengguna miliki di perangkat komputer Pengguna, selain dari yang telah Pengguna setujui untuk disampaikan.</p>
                                        </li>
                                        <li>
                                            <p>walaupun secara otomatis perangkat komputer Pengguna akan menerima cookies, Pengguna dapat menentukan pilihan untuk melakukan modifikasi melalui pengaturan browser Pengguna yaitu dengan memilih untuk menolak cookies (pilihan ini dapat menghambat tersedianya layanan PT. Ide Picup Group secara optimal pada saat Pengguna mengakses Aplikasi).</p>
                                        </li>
                                        <li>
                                            <p>PT. Ide Picup Group menggunakan fitur Google Analytics Demographics and Interest. Data yang PT. Ide Picup Group peroleh dari fitur tersebut, seperti umur, jenis kelamin, minat Pengguna dan informasi lainnya yang dapat mengidentifikasi Pengguna, akan PT. Ide Picup Group gunakan untuk pengembangan fitur, fasilitas, dan/atau konten yang terdapat pada Aplikasi PT. Ide Picup Group. Jika Pengguna tidak ingin data miliknya terlacak oleh Google Analytics, Pengguna dapat menggunakan Add-On Google Analytics Opt-Out Browser.</p>
                                        </li>
                                        <li>
                                            <p>PT. Ide Picup Group dapat menggunakan fitur-fitur yang disediakan oleh pihak ketiga untuk meningkatkan layanan dan konten PT. Ide Picup Group, antara lain, adalah penilaian, penyesuaian, dan penyajian iklan kepada setiap Pengguna berdasarkan minat atau riwayat kunjungan. Jika Pengguna tidak ingin iklan ditampilkan berdasarkan penyesuaian tersebut, maka Pengguna dapat mengaturnya melalui browser.</p>
                                        </li>
                                    </ol>
                                    <h4>E. Pilihan Pengguna dan Transparansi</h4>
                                    <ol>
                                        <li>
                                            <p>Perangkat seluler pada umumnya (iOS dan Android) memiliki pengaturan sehingga aplikasi PT. Ide Picup Group tidak dapat mengakses data tertentu tanpa persetujuan dari Pengguna. Perangkat iOS akan memberikan pemberitahuan kepada Pengguna saat aplikasi PT. Ide Picup Group pertama kali meminta akses terhadap data tersebut, sedangkan perangkat Android akan memberikan pemberitahuan kepada Pengguna saat aplikasi PT. Ide Picup Group pertama kali dimuat. Dengan menggunakan aplikasi dan memberikan akses terhadap aplikasi, Pengguna dianggap memberikan persetujuannya terhadap pengumpulan dan penggunaan Data Pribadi Pengguna dalam perangkatnya.</p>
                                        </li>
                                        <li>
                                            <p>Setelah transaksi jual beli melalui Aplikasi berhasil, Penjual maupun Pembeli (sebagaimana didefinisikan dalam Syarat dan Ketentuan) memiliki kesempatan untuk memberikan penilaian dan ulasan terhadap satu sama lain. Informasi ini mungkin dapat dilihat secara publik dengan persetujuan Pengguna.</p>
                                        </li>
                                        <li>
                                            <p>Pengguna dapat mengakses dan mengubah informasi berupa alamat email, nomor telepon, tanggal lahir, jenis kelamin, daftar alamat, metode pembayaran, dan rekening bank melalui fitur pengaturan (settings) pada Aplikasi.</p>
                                        </li>
                                        <li>
                                            <p>Pengguna dapat menarik diri (opt-out) dari informasi atau notifikasi berupa buletin, ulasan, diskusi produk, pesan pribadi, atau pesan pribadi dari Admin yang dikirimkan oleh PT. Ide Picup Group melalui fitur pengaturan pada Aplikasi. PT. Ide Picup Group tetap dapat mengirimkan pesan atau email berupa keterangan transaksi atau informasi terkait akun Pengguna.</p>
                                        </li>
                                        <li>
                                            <p>Sepanjang tidak bertentangan dengan ketentuan peraturan perundang-undangan yang berlaku, Pengguna dapat menghubungi PT. Ide Picup Group untuk melakukan penarikan persetujuan terhadap perolehan, pengumpulan, penyimpanan, pengelolaan dan penggunaan data Pengguna. Apabila terjadi demikian maka Pengguna memahami konsekuensi bahwa Pengguna tidak dapat menggunakan layanan Aplikasi maupun layanan PT. Ide Picup Group lainnya.</p>
                                        </li>
                                    </ol>
                                    <h4>F. Keamanan, Penyimpanan dan Penghapusan Data Pribadi Pengguna</h4>
                                    <ol>
                                        <li>
                                            <p>PT. Ide Picup Group melindungi setiap Data Pribadi Pengguna yang disimpan dalam sistemnya, serta melindungi data tersebut dari akses, penggunaan, modifikasi, pengambilan dan/atau pengungkapan tidak sah dengan menggunakan sejumlah tindakan dan prosedur keamanan, termasuk kata sandi dan kode OTP (One Time Password) Pengguna.</p>
                                        </li>
                                        <li>
                                            <p>Data Pribadi Pengguna juga dapat disimpan atau diproses di luar negara oleh pihak yang bekerja untuk PT. Ide Picup Group di negara lain, atau oleh penyedia layanan pihak ketiga, vendor, pemasok, mitra, kontraktor, atau afiliasi PT. Ide Picup Group. Dalam hal tersebut, PT. Ide Picup Group akan memastikan bahwa Data Pribadi tersebut tetap terlindungi sesuai dengan komitmen PT. Ide Picup Group dalam Kebijakan Privasi ini.</p>
                                        </li>
                                        <li>
                                            <p>Walaupun PT. Ide Picup Group telah menggunakan upaya terbaiknya untuk mengamankan dan melindungi Data Pribadi Pengguna, perlu diketahui bahwa pengiriman data melalui Internet tidak pernah sepenuhnya aman. Dengan demikian, PT. Ide Picup Group tidak dapat menjamin 100% keamanan data yang disediakan atau dikirimkan kepada PT. Ide Picup Group oleh Pengguna dan pemberian informasi oleh Pengguna merupakan risiko yang ditanggung oleh Pengguna sendiri.</p>
                                        </li>
                                        <li>
                                            <p>PT. Ide Picup Group akan menghapus dan/atau menganonimkan Data Pribadi Pengguna yang ada di bawah kendali PT. Ide Picup Group apabila (i) Data Pribadi Pengguna tidak lagi diperlukan untuk memenuhi tujuan dari pengumpulannya; dan (ii) penyimpanan tidak lagi diperlukan untuk tujuan kepatuhan terhadap peraturan perundang-undangan yang berlaku.&nbsp;</p>
                                        </li>
                                        <li>
                                            <p>Mohon diperhatikan bahwa masih ada kemungkinan bahwa beberapa Data Pribadi Pengguna disimpan oleh pihak lain, termasuk instansi penyelenggara negara yang berwenang. Dalam hal kami membagikan Data Pribadi Pengguna kepada instansi penyelenggara negara yang berwenang dan/atau instansi lainnya yang dapat ditunjuk oleh pemerintah yang berwenang atau memiliki kerja sama dengan PT. Ide Picup Group, Pengguna menyetujui dan mengakui bahwa penyimpanan Data Pribadi Pengguna oleh instansi tersebut akan mengikuti kebijakan penyimpanan data masing-masing instansi tersebut.&nbsp;</p>
                                        </li>
                                    </ol>
                                    <h4>G. Akses dan Perbaikan Data Pribadi Pengguna atau Penarikan Kembali Persetujuan</h4>
                                    <ol>
                                        <li>
                                            <p>PT. Ide Picup Group mengambil langkah-langkah yang wajar untuk memastikan bahwa Data Pribadi Pengguna diproses secara akurat dan lengkap. Namun demikian, penting bagi Pengguna untuk memberi tahu PT. Ide Picup Group secara tepat waktu tentang segala perubahan pada Data Pribadi Pengguna atau jika ada kesalahan dalam Data Pribadi Pengguna yang berada di bawah kendali PT. Ide Picup Group.</p>
                                        </li>
                                        <li>
                                            <p>Pengguna berhak untuk mengakses atau mengoreksi Data Pribadi Pengguna yang berada di bawah kendali PT. Ide Picup Group melalui layanan pelanggan PT. Ide Picup Group yang tersedia dalam Kebijakan Privasi ini. Namun demikian, permohonan tersebut hanya akan diproses oleh PT. Ide Picup Group apabila Pengguna telah menyerahkan bukti identitas yang memadai untuk melakukan akses atau koreksi terhadap data tersebut. PT. Ide Picup Group berhak menolak permohonan untuk mengakses, atau untuk memperbaiki, sebagian atau semua Data Pribadi Pengguna yang PT. Ide Picup Group miliki atau kuasai jika diizinkan atau diperlukan berdasarkan perundang-undangan yang berlaku. Hal ini termasuk dalam keadaan di mana Data Pribadi tersebut dapat berisi referensi kepada orang lain atau di mana permintaan untuk akses atau permintaan untuk mengoreksi adalah untuk alasan yang PT. Ide Picup Group anggap tidak relevan, tidak serius, atau menyulitkan.</p>
                                        </li>
                                        <li>
                                            <p>PT. Ide Picup Group dapat membebankan biaya administrasi kepada Pengguna untuk menangani permintaan Pengguna untuk mengakses atau mengoreksi Data Pribadi Pengguna.&nbsp;</p>
                                        </li>
                                        <li>
                                            <p>Pengguna dapat menarik persetujuan yang telah Pengguna berikan terkait dengan pemrosesan Data Pribadi Pengguna yang ada di bawah kendali PT. Ide Picup Group dengan mengirimkan permintaan tersebut melalui layanan pelanggan PT. Ide Picup Group yang tercantum dalam Kebijakan Privasi ini. PT. Ide Picup Group akan memproses permintaan Pengguna dalam kurun waktu yang wajar sejak permintaan penarikan persetujuan tersebut disampaikan, dan selanjutnya tidak memproses Data Pribadi Pengguna sesuai dengan permintaan awal Pengguna, kecuali diwajibkan oleh peraturan perundang-undangan yang berlaku. Dalam hal terdapat penarikan persetujuan, PT. Ide Picup Group mungkin tidak dapat melaksanakan kewajiban-kewajibannya berdasarkan setiap perjanjian antara Pengguna dengan PT. Ide Picup Group. Sehubungan dengan hal tersebut, terdapat juga kemungkinan bahwa dikarenakan PT. Ide Picup Group tidak dapat melaksanakan kewajiban-kewajibannya oleh karena Pengguna menarik persetujuannya, setiap hubungan hukum antara Pengguna dan PT. Ide Picup Group menjadi berakhir dan tidak dapat dilanjutkan.&nbsp;</p>
                                        </li>
                                    </ol>
                                    <h4>H. Pengaduan terkait Perlindungan Data Pengguna</h4>
                                    <ol>
                                        <li>
                                            <p>Jika Pengguna memiliki kekhawatiran tentang penanganan atau perlakuan PT. Ide Picup Group terhadap Data Pribadi Pengguna atau jika Pengguna yakin bahwa privasinya telah dilanggar, Pengguna dapat menghubungi PT. Ide Picup Group melalui customer service PT. Ide Picup Group atau melalui kontak PT. Ide Picup Group yang tercantum dalam Kebijakan Privasi ini dengan menjelaskan identitas dan keluhan Pengguna.</p>
                                        </li>
                                        <li>
                                            <p>PT. Ide Picup Group akan menyelidiki keluhan Pengguna dan berupaya untuk memberikan tanggapan terhadap keluhan tersebut dalam kurun waktu yang wajar setelah menerima keluhan yang disampaikan oleh Pengguna.</p>
                                        </li>
                                    </ol>
                                    <h4>I. Penyimpanan dan Penghapusan Informasi</h4>
                                    <p>Selama akun pengguna tetap aktif, PT Ide Picup Group akan menyimpan informasi pengguna dan dapat menghapusnya sesuai dengan ketentuan hukum yang berlaku.</p>
                                    <h4>K. Pembaruan Kebijakan Privasi</h4>
                                    <p>PT. Ide Picup Group dapat sewaktu-waktu melakukan perubahan atau pembaruan terhadap Kebijakan Privasi ini. PT. Ide Picup Group menyarankan agar Pengguna membaca secara seksama dan memeriksa halaman Kebijakan Privasi ini dari waktu ke waktu untuk mengetahui perubahan apapun. Dengan tetap mengakses dan menggunakan layanan Aplikasi maupun layanan PT. Ide Picup Group lainnya, maka Pengguna dianggap menyetujui perubahan-perubahan dalam Kebijakan Privasi.</p>
                                    <h4>L. Hubungi Kami</h4>
                                    <p>Dalam hal Pengguna memiliki pertanyaan, komentar, keluhan, atau klaim mengenai Kebijakan Privasi ini atau Pengguna ingin mendapatkan akses dan/atau melakukan koreksi terhadap Data Pribadi miliknya, silakan hubungi layanan pelanggan PT. Ide Picup Group</p>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="row">
                                <div className="col text-left">
                                    <br></br>
                                    <h4 style={{ borderBottom: 'solid black 3px', fontSize: '20px' }}>Privacy Policy<br></br><br></br></h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-left">
                                    <p>The existence of this Privacy Policy is a real commitment from PT. Ide Picup Group is to respect and protect any personal data or information of users of the thefepi or www.thefepi.co.id Applications, their derivative applications, as well as the PT. Ide Picup Group (hereinafter referred to as &quot;Application&quot;).</p>
                                    <p>This Privacy Policy (along with the terms of use of the PT. Ide Picup Group Application as stated in the Terms &amp; Conditions and other information contained in the Application) establishes the basis for obtaining, collecting, processing, analyzing, displaying, transmitting, opening, storing, changing , deletion and/or all forms of management related to data or information that identifies or can be used to identify Users that Users provide to PT. The Ide of the Picup Group or the PT. Idea Picup Group collects from Users and third parties (hereinafter referred to as &quot;Personal Data&quot;).</p>
                                    <p>By clicking &quot;Register&quot; (Register) or a similar statement on the registration page, the user declares that the personal data provided is true and valid data, the User acknowledges that he has been informed and understands this Privacy Policy and gives his consent to PT. Ide Picup Group is to obtain, collect, process, analyze, display, transmit, open, store, modify, delete, manage and/or use such data for the purposes as stated in the Privacy Policy.</p>
                                    <h4>A. Collection and Collection of User&apos;s Personal Data</h4>
                                    <p>User data is collected by PT Ide Picup Group with the aim of processing transactions made by users, managing and streamlining the process of using the Application, as well as other purposes permitted by applicable laws and regulations. User Data collected is as follows:</p>

                                    <ol>
                                        <li>
                                            <p>Data submitted independently by the User, including but not limited to data submitted when the User:</p>
                                            <ol>
                                                <li>
                                                    <p>create or update PT Ide Picup Group,, including usernames, email addresses, phone numbers, passwords, addresses, photos, and other information that can identify Users;</p>
                                                </li>
                                                <li>
                                                    <p>contact PT. Ide Picup Group, including through customer service;</p>
                                                </li>
                                                <li>
                                                    <p>fill out a survey sent by PT. Ide Picup Group or other parties officially appointed to represent PT. Ide Picup Group;</p>
                                                </li>
                                                <li>
                                                    <p>interact with other Users through messaging features, product discussions, reviews, ratings, Resolution Center (as defined in the Terms and Conditions) and other interactive features (either one-way or two-way) contained in the Application;</p>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                    <ol start="5">
                                        <li>
                                            <p>use the services on the Application, including detailed transaction data, including the type, amount and/or description of the product or service purchased, delivery address, payment channel used, transaction amount, transaction date and time, and other transaction details;</p>
                                        </li>
                                        <li>
                                            <p>fill in payment data when the User performs payment transaction activities through the Application, including but not limited to bank account data, credit cards, virtual accounts, instant payments, internet banking, retail outlets; and/or</p>
                                        </li>
                                        <li>
                                            <p>use features on the Application that require access permission to the relevant data stored in the User&apos;s device.</p>
                                        </li>
                                    </ol>
                                    <ol start="2">
                                        <li>
                                            <p>Data recorded when the User uses the Application, including but not limited to:</p>
                                            <ol>
                                                <li>
                                                    <p>real or approximate location data such as IP address, Wi-Fi location and geo-location;</p>
                                                </li>
                                                <li>
                                                    <p>data in the form of time from each User activity in connection with the use of the Application, including registration, login and transaction times;</p>
                                                </li>
                                                <li>
                                                    <p>User usage data or preferences, including User interactions in using the Application, saved options, and selected settings. The data is obtained using cookies, pixel tags and similar technologies that create and maintain a unique identifier;</p>
                                                </li>
                                                <li>
                                                    <p>device data, including the type of device used to access the Application, including hardware model, operating system and version, software, IMEI number, file name and version, language selection, unique device identifier, advertising identifier, serial number, device movement information, and/or cellular network information; and/or</p>
                                                </li>
                                                <li>
                                                    <p>log data, including records on servers that receive data such as device IP addresses, access dates and times, application features or pages viewed, application work processes and other system activities, browser type, and/or applications or services third parties that the User uses before interacting with the Application.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p>Data obtained from other sources, including but not limited to:</p>
                                            <ol>
                                                <li>
                                                    <p>data in the form of geo-location (GPS) from business partners PT. ide &nbsp;Picup Group that helped PT. Ide Picup Group is to develop and provide services in the Application to Users, including payment service providers, logistics or courier partners, Application infrastructure, and other partners.</p>
                                                </li>
                                                <li>
                                                    <p>data in the form of email, telephone number, name, gender, and/or date of birth of a business partner of PT. Idea Pickup Group where Users create or access PT. Ide Picup Group, such as social media services, or applications that use the application programming interface (API) of PT. Ide Picup Group or the one used by PT. Ide Picup Group;</p>
                                                </li>
                                                <li>
                                                    <p>data from financial service providers, including but not limited to credit rating agencies or bureaus or the Credit Information Management Agency (LPIP);</p>
                                                </li>
                                                <li>
                                                    <p>data from financial service providers (if the User uses specific features such as applying for a loan through the PT. Ide Picup Group Application/Application); and/or</p>
                                                </li>
                                                <li>
                                                    <p>data in the form of emails from marketing service providers.</p>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                    <p>PT. Ide Picup Group may combine data obtained from these sources with other data in its possession.</p>

                                    <h4>B. Personal Data Usage</h4>
                                    <p>As previously mentioned, personal data that has been obtained and collected from users may be used by PT. Ide Picup Group for several things, namely:</p>

                                    <ol>
                                        <li>
                                            <p>Process all forms of requests, activities or transactions made by Users through the Application, including for the purpose of sending products to Users.</p>
                                        </li>
                                        <li>
                                            <p>Provision of features to deliver, deliver, maintain and improve our products and services, including:</p>
                                            <ol>
                                                <li>
                                                    <p>offer, acquire, provide, or facilitate marketplace services such as homepage, search, search, product recommendation, PT. Ide Picup Group, insurance, financing, loans, and other products through the Application;</p>
                                                </li>
                                                <li>
                                                    <p>allows the feature to personalize PT. Ide Picup Group, such as Shopping Cart, Wishlist and Favorite Stores; and/or</p>
                                                </li>
                                                <li>
                                                    <p>perform internal activities required to provide services on the PT. Ide Picup Group, such as troubleshooting software problems, bugs, operational issues, conducting data analysis, testing, and research, and to monitor and analyze usage trends and activities.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p>Helping Users when communicating with PT. Ide Picup Group, including for:</p>
                                            <ol>
                                                <li>
                                                    <p>examine and resolve User problems;</p>
                                                </li>
                                                <li>
                                                    <p>direct User inquiries to appropriate customer service personnel to resolve issues;</p>
                                                </li>
                                                <li>
                                                    <p>monitor and improve customer service response PT. Ide Picup Groups;</p>
                                                </li>
                                                <li>
                                                    <p>contacting the User via email, letter, telephone, fax, and other communication methods, including but not limited to, to assist and/or complete the transaction process or problem solving process, as well as deliver news or other notifications in connection with the protection of User&apos;s Personal Data by PT. Ide Picup Group, including failure to protect User&apos;s Personal Data;</p>
                                                </li>
                                                <li>
                                                    <p>use information obtained from Users for the purpose of research, analysis, development and product testing in order to improve the security of services on the Application, as well as develop new features and products; and</p>
                                                </li>
                                                <li>
                                                    <p>Inform users regarding products, services, promotions, studies, surveys, news, latest developments, events and other information, either through the Application or through other media. PT. Ide Picup Group may also use this information to promote and process contests and sweepstakes, award prizes, and severe relevant advertisements and content about the services of PT. Ide Picup Group and its business partners.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p>Monitor or investigate suspicious transactions or transactions indicated to contain elements of fraud or violations of the Terms and Conditions or applicable legal provisions, as well as take necessary actions as a follow-up to the results of monitoring or investigating these transactions.</p>
                                        </li>
                                        <li>
                                            <p>Under certain circumstances, PT. Ide Picup Group may need to use or disclose User&rsquo;s Personal Data for law enforcement purposes or to fulfill the requirements and obligations of applicable laws and regulations, including in the event of a dispute or legal process between the User and PT. Ide Picup Group, or suspected criminal acts such as fraud or data theft.</p>
                                        </li>
                                        <li>
                                            <p>Facilitate merger transactions, sale of company assets, consolidation or restructuring, financing or acquisitions involving PT. Ide Picup Group. &nbsp;</p>
                                        </li>
                                    </ol>
                                    <h4>C. Disclosure of User&rsquo;s Personal Data</h4>
                                    <ol>
                                        <li>
                                            <p>Commitment of PT. Ide Picup Group is to maintain the confidentiality of the personal data of its user and ensure that there is no disclosure, sale, transfer, distribution and/or borrowing of User&rsquo;s Personal Data to other third parties, without the User&rsquo;s consent, except in the following cases:&nbsp;</p>
                                            <ol>
                                                <li>
                                                    <p>It is necessary to disclose the User&rsquo;s Personal Data to partners or other third parties who helped PT. Ide Picup Group to provide available services or services that will later be available on the application and process all forms of user activity within the application. Including processing transactions, payment verification, promotions and product delivery.</p>
                                                </li>
                                                <li>
                                                    <p>PT. Ide Picup Group can provide User&rsquo;s Personal Data to business partners in accordance with the user&rsquo;s agreement to use the services of business partners, including other applications or applications that have integrated APIs or their services, or business partners in which PT. Ide Picup Group has collaborated to organize special promotions, competitions or services available on the application.&nbsp;</p>
                                                </li>
                                                <li>
                                                    <p>PT. Ide Picup Group can provide User Personal Data to third parties who use and integrate the public API provided by PT. Ide Picup Group (Including but not limited to service providers business partners of PT. Ide Picup Group) with applications or applications that operate for the purpose of completing transactions between buyers and sellers on the application or other purposes of using Personal Data as mentioned in Part B of this Privacy Policy.&nbsp;</p>
                                                </li>
                                                <li>
                                                    <p>Communication between the business partners of PT. Ide Picup Group (such as logistics providers, payments, and other feature or service facilities providers) is needed with users in terms of resolving problems or other matters.</p>
                                                </li>
                                                <li>
                                                    <p>PT. Ide Picup Group can provide User Personal Data to vendors, consultants, marketing partners, research firms/institutions, or similar service providers in the context of marketing activities carried out by third parties, improving and maintaining the quality of PT. Ide Picup Group, as well as other publication activities.</p>
                                                </li>
                                                <li>
                                                    <p>User contact PT. Ide Picup Group through public media such as blogs, social media, and certain features on the application, which is a communication between the user and PT. Ide Picup Group can be seen and known by the general public</p>
                                                </li>
                                                <li>
                                                    <p>PT. Ide Picup Group may share User&rsquo;s Personal Data with its subsidiaries and affiliates to help provide services or perform data processing for and on behalf of PT. Ide Picup Group.</p>
                                                </li>
                                                <li>
                                                    <p>PT. Ide Picup Group may disclose and/or provide User&rsquo;s Personal Data to interested parties in the context of carrying out a User creditworthiness analysis.&nbsp;</p>
                                                </li>
                                                <li>
                                                    <p>PT. Ide Picup Group discloses User&rsquo;s Personal Data in an effort to comply with legal obligations and/or legitimate requests from law enforcement officers or authorizes state administration agencies.</p>
                                                </li>
                                                <li>
                                                    <p>PT. Ide Picup Group can provide User Personal Data to business partners in accordance with User consent to use business partners services, including the Facebook application, or business partners where PT. Ide Picup Group has collaborated to organize promotions, competitions or special services available on The Fepi application and website.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p>In connection with the disclosure of User&rsquo;s Personal Data as described above, PT. Ide Picup Group will/may need to disclose User&rsquo;s Personal Data to third party service providers, agents and/or affiliates or companies related to PT. Ide Picup Group and/or other third parties located outside the territory of Indonesia. However, third party service providers, agents and/or affiliates or related companies and/or other third parties will only manage nad/or utilize User&rsquo;s Personal Data in or more purposes as regulated in this Privacy Policy and in provisions of the regulations. Applicable laws and regulations.</p>
                                        </li>
                                    </ol>
                                    <h4>D. Cookies</h4>
                                    <ol>
                                        <li>
                                            <p>Cookies are small files that will automatically take place on the User&rsquo;s device that performs the function of saving user preferences and configurations while visiting an application.</p>
                                        </li>
                                        <li>
                                            <p>These cookies are not intended to be used when accessing other data that the user has on the user&rsquo;s computer, other than what the user has agreed to submit.</p>
                                        </li>
                                        <li>
                                            <p>Although the user&rsquo;s computer device will automatically accept cookies, the user can make the choice to make modifications through the user&rsquo;s browser settings, namely by choosing to refuse cookies (this choice can prevent the optimal availability of PT. Ide Picup Group services when the user accesses the application).walaupun secara otomatis perangkat komputer Pengguna akan menerima cookies, Pengguna dapat menentukan pilihan untuk melakukan modifikasi melalui pengaturan browser Pengguna yaitu dengan memilih untuk menolak cookies (pilihan ini dapat menghambat tersedianya layanan PT. Ide Picup Group secara optimal pada saat Pengguna mengakses Aplikasi).</p>
                                        </li>
                                        <li>
                                            <p>PT. Ide Picup Group uses the Google Analytics Demographics and Interest feature. Data thet PT. Ide Picup Group derives from these features, such as age, gender, user interests and other information that can identify users, PT. Ide Picup Group is used for the development of features, facilities and/or content contained in the PT. Ide Picup Group. If the user does not want his data to be tracked by Google Analytics, the user can use the Google Analytics Opt-Out Browser Add-On.</p>
                                        </li>
                                        <li>
                                            <p>PT. Ide Picup Group may use the features provided by third parties to improve the services and content of PT. Ide Picup Group, among other things, is the assessment, adjustment and serving of advertisements to each user based on interests or visit history. If the user does want ads to be displayed based on these adjustments, then the user can set it through the browser.</p>
                                        </li>
                                    </ol>
                                    <h4>E. User Choice and Transparency</h4>
                                    <ol>
                                        <li>
                                            <p>Mobile devices in general (iOS and Android) have setting so that the PT. Ide Picup Group application cannot access certain data without the consent of the user. The iOS device will notify the user when the PT. Ide Picup Group application first requests access to the data, while the Android device will notify the user when the PT. Ide Picup Group application loads first. By using the application and providing access to the application, the user is deemed to have given his consent to the collection and use of the User&rsquo;s Personal Data on his device.&nbsp;</p>
                                        </li>
                                        <li>
                                            <p>After the sale and purchase transaction through the application is successful, the Seller and the Buyer (as defined in the Terms and Conditions) have the opportunity to provide an assessment and review of each other. This information may be publicly viewable with the user&rsquo;s consent.&nbsp;</p>
                                        </li>
                                        <li>
                                            <p>Users can access and change information in the form of email addresses, phone numbers, date of birth, gender, address list, payment methods, and bank accounts through the settings feature on the application.</p>
                                        </li>
                                        <li>
                                            <p>Users can opt-out of receiving information or notifications from PT. Ide Picup Group in the form of bulletins, reviews, product discussions, private messages, or private messages from the Admin via the Application&apos;s settings feature. PT. Ide Picup Group may continue to send messages or emails describing transactions or providing information about User accounts.</p>
                                        </li>
                                        <li>
                                            <p>As long as it does not conflict with the terms of applicable laws and regulations, the User may withdraw consent to the acquisition, collecting, storage, management, and use of User data by contacting PT. Ide Picup Group. If this occurs, the User acknowledges the ramifications of being unable to utilize the Application&apos;s services or other services of PT. Ide Picup Group.</p>
                                        </li>
                                    </ol>
                                    <h4>F. Security, Storage and Deletion of User&apos;s Personal Data</h4>
                                    <ol>
                                        <li>
                                            <p>PT. Ide Picup Group safeguards each User&apos;s Personal Data held on its system by implementing a variety of security methods and procedures, including passwords and OTP (One Time Password) codes.</p>
                                        </li>
                                        <li>
                                            <p>Users&apos; Personal Data may also be kept or processed outside the nation by parties working for PT. Ide Picup Group in other countries, or by third-party service providers, vendors, suppliers, partners, contractors, or affiliates of PT. Ide Picup Group. That is the situation, PT. Ide Picup Group shall ensure that Personal Data is protected in accordance with the PT. Ide Picup Group&apos;s commitments set forth in this Privacy Statement.</p>
                                        </li>
                                        <li>
                                            <p>While PT. Ide Picup Group has made reasonable measures to secure and protect Users&apos; Personal Data, please keep in mind that no data transmission over the Internet is 100% secure. As a result, PT. Ide Picup Group cannot ensure the security of data supplied to or sent to PT. Ide Picup Group and providing of information is entirely at the User&apos;s risk.</p>
                                        </li>
                                        <li>
                                            <p>PT. Ide Picup Group will erase and/or anonymize any User Personal Data in its possession. PT. Ide Picup Groups are formed when the User&apos;s Personal Data is no longer required to fulfill the purpose for which it was obtained; and (ii) retention is no longer necessary to comply with applicable laws and regulations.</p>
                                        </li>
                                        <li>
                                            <p>Please keep in mind that certain Users&apos; Personal Data may still be held by third parties, including approved state administration agencies. If we share User&apos;s Personal Data with authorized state administration agencies and/or other agencies appointed by the competent government or working with PT. Ide Picup Group, the User agrees and acknowledges that the agency&apos;s storage of User&apos;s Personal Data will adhere to the agency&apos;s data retention policy.</p>
                                        </li>
                                    </ol>
                                    <h4>G. Access and Correction of User&apos;s Personal Data or Withdrawal of Consent</h4>
                                    <ol>
                                        <li>
                                            <p>PT. Ide Picup Group takes reasonable precautions to ensure the accuracy and completeness of User&apos;s Personal Data. However, it is critical for the User to tell PT. Ide Picup Group promptly of any changes to the User&apos;s Personal Data or if there are any inaccuracies in the User&apos;s Personal Data that are within PT. Ide Picup Group&apos;s control.</p>
                                        </li>
                                        <li>
                                            <p>Through customer service, the User has the right to access and correct User&apos;s Personal Data that is under the control of PT. Ide Pickup Group. This Privacy Statement contains information on PT. Ide Picup Group. However, PT. Ide Picup Group will process the application only if the User provides appropriate proof of identity to access or modify the data. Where permitted or required by applicable legislation, PT. Ide Picup Group maintains the right to deny any application for access to, or correction of, part or all of the User&apos;s Personal Data that PT. Ide Picup Group owns or controls. This includes instances when Personal Data may contain references to other individuals or where a request for access or modification is deemed irrelevant, unserious, or difficult by PT. Ide Picup Group.</p>
                                        </li>
                                        <li>
                                            <p>The Ide Picup Group has the right to charge the User an administrative fee for processing the User&apos;s request to access or rectify Personal Data.</p>
                                        </li>
                                        <li>
                                            <p>The User may revoke any consent given in respect to the processing of Personal Data under the control of PT. Ide Pickup Group by contacting customer care. This Privacy Statement contains the opinions of PT. Ide Picup Group. PT. Ide Picup Group shall process the User&apos;s request for withdrawal of permission within a reasonable time after it is made, and will then cease processing User&apos;s Personal Data in line with the User&apos;s initial request, unless compelled by applicable laws and regulations. PT. Ide Picup Group may be unable to perform its responsibilities under any agreement between the User and PT. Group Pickup Ideas if clearance is withdrawn. Additionally, if PT. Ide Picup Group is unable to perform its responsibilities as a result of the User withdrawing his consent, any legal relationship between the User and PT. Ide Picup Group has ended and cannot be extended.</p>
                                        </li>
                                    </ol>
                                    <h4>H. Complaints regarding User Data Protection</h4>
                                    <ol>
                                        <li>
                                            <p>If the User has any questions or concerns about PT. Ide Pickup Group&apos;s management or treatment of the User&apos;s Personal Data, or if the User believes his privacy has been infringed, the User may contact PT. Ide Pickup Group via customer service PT. Ide Pickup Group or PT. Ide Picup Group concept is embodied in this Privacy Policy, which details the identity of Users and their concerns.</p>
                                        </li>
                                        <li>
                                            <p>PT. Ide Picup Group will investigate User concerns and will make every effort to respond to them within a reasonable time after receiving them.</p>
                                        </li>
                                    </ol>
                                    <h4>I. Storage and Deletion of Information</h4>
                                    <p>PT Ide Picup Group will retain user information for as long as the user account is active and may remove it in compliance with applicable legal regulations.</p>
                                    <h4>K. Privacy Policy Update</h4>
                                    <p>PT. Ide Picup Group reserves the right to modify or alter this Privacy Statement at any time. PT. Ide Picup Group strongly advises Users to read this Privacy Statement carefully and to visit this page periodically for modifications. By continuing to access and use the Application and PT. Ide Picup Group concepts, the User is understood to have agreed to the Privacy Policy amendments.</p>
                                    <h4>L. Contact Us</h4>
                                    <p>If the User has any questions, comments, complaints, or claims regarding this Privacy Policy, or if the User wishes to gain access to and/or amend his/her Personal Data, the User should contact PT. Ide Picup Group.</p>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                        </>
                }
            </div>
        );
    }
}

export default PrivacyPolicyScreen;