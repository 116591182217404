import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import ChannelChatPopup from "../../CommonComponents/UI/ChannelChatPopup/ChannelChatPopup";
import PointViewModel from "./PointViewModel";
import ButtonWidget, { ButtonWidgetType } from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import { Navigate  } from "react-router-dom";

import "./Point.css";
import ic_thefepi_f from "../../Assets/Images/Logo/ic_fepi_f.svg";
import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
import ViewController from "../../CommonComponents/Base/ViewController";

export default class PointScreen extends ViewController<{}, PointViewModel> {
    constructor(props: {}) {
        super(props, new PointViewModel());
        this.viewModel.loadPreloadingProps();
    }

    handleChangePoint = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.viewModel.handleChangePoint(event.target.value);
    }

    handleUseAll = (point: number = 0) => {
        this.viewModel.handleUseAll(point);
    }

    Apply = () => {
        // if(localStorage.use_coupon !== undefined)
        // {
        //     localStorage.removeItem("use_coupon")
        //     localStorage.removeItem("couponName")
        //     localStorage.removeItem("couponId")
        // }
        window.location.href = '/Checkout';
    }
    
    render() {
        const pointData = this.viewModel.pointData;
        const DeeplinkHeight = 46;
        const fixedHeight = 72;

        if (localStorage.getItem("selectedBagItem") === null) {
            return <Navigate  to='/'/>;
        }
        return(
            <Scaffold 
                isShowLoading={this.viewModel.isLoading}
                showSnackbar={this.viewModel.snackbarMessage}
                showErrorModel={this.viewModel.errorModel}
            >
                <div id="Point" className="container">
                    <p className="font14 margin0"><b>Point</b></p>
                    <div className="row ptop20">
                        <div className="col text-center">
                            <div className='box_white_cart'>
                                <div className="row">
                                    <div className="col-8 text-end">
                                        <p className="font24"><b>{pointData?.point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b></p>
                                    </div> 
                                    <div className="col-4 no-padding text-start">
                                        <p className="font24"><b>P</b></p>
                                    </div>
                                    <p className="youhave">Points you have</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <br></br>
                            <p style={{fontSize:'14px',fontFamily:'SF Pro Reguler'}}>Use Points for Purchase</p>
                            <div className='box_white_cart'>
                                <div className="row">
                                    <div className="col-12 text-end">
                                        <input type="text"
                                        id="input_point" 
                                        onChange= {this.handleChangePoint} 
                                        className="point_field"
                                        defaultValue={localStorage.point !== undefined ? localStorage.point.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}
                                        placeholder='Enter amount'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-end">
                            <input type="radio" onChange= {() => this.handleUseAll(pointData?.point)}></input><span className="font14">&nbsp;&nbsp;Use all of points <input type="text" className="point_field_all"  value={pointData?.point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} /></span>
                        </div>
                    </div>

                    <div id="ProductTopGradient" className="row" style={{bottom:`${(DeeplinkHeight+fixedHeight)-75}px`}}/>
                    <div className="row fixed">
                        <div className="col-2 text-center paddingtop20" data-bs-toggle="modal" data-bs-target="#ChatModal">
                            <img width="20" className="img-fluid" src={global.ImgUrl+'shopable/ic-detail-chat@2x.png'} alt="gambar1"></img>
                        </div>
                        <div className="col-10 text-center paddingtop10">
                            <ButtonWidget 
                                buttonType={ButtonWidgetType.primary} 
                                isLoading={this.viewModel.isLoadingButtonSubmit ? true : false}
                                isEnabled={localStorage.getItem("selectedBagItem") === null ? false : true}
                                width="calc(100% - 6px)"
                                action={() => {
                                    this.Apply();
                                }} 
                            >
                                Apply
                            </ButtonWidget>
                        </div>
                    </div>

                    <div id="SpaceNavigationBackground" className="row" style={{opacity:`${this.viewModel.navBarTransparency}`}}></div>                
                    <div className="row floatingButton">
                        <button id="SpaceHomeButton" onClick={() => window.location.href = "/"}>
                            <img src={ic_thefepi_f} alt="" />
                        </button>
                        <button id="SpaceCartButton" onClick={() => {
                            window.location.href = "/Cart";
                        }}>
                            <img src={ic_cart} alt="" />
                            {
                                (this.viewModel.numberInCart ?? 0) > 0 && <div>{this.viewModel.numberInCart}</div>
                            }
                        </button>
                    </div>

                    <ChannelChatPopup />
                </div>
            </Scaffold>
        );
    }
} 