import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import AddressDetailResponse from "./Models/AddressDetailResponse";

export default class AddressUpdaterViewModel extends ViewModel {
    shippingAddressId?: string;
    name: string;
    phone: string;
    email: string;
    addressDetail: string;
    addressLabel?: string;
    latitude?: number;
    longitude?: number;

    isLoading: boolean = false;
    isSubmitting: boolean = false;
    errorMessage?: string;
    errorSnackbarMessage?: ShowSnackbarModel;

    constructor(
        shippingAddressId?: string,
        name: string = "",
        phone: string = "",
        email: string = "",
        addressDetail: string = "",
        addressLabel?: string,
        latitude?: number,
        longitude?: number,
    ) {
        super();

        this.shippingAddressId = shippingAddressId;
        this.name = name;
        this.phone = phone;
        this.email = email;
        this.addressDetail = addressDetail;
        this.addressLabel = addressLabel;
        this.latitude = latitude;
        this.longitude = longitude;
    }

    getDetail() {
        this.emit(() => {
            this.isLoading = true;
            this.errorMessage = undefined;
        });

        requestAPI(localStorage.fpAuth ? Endpoints.addressDetail : Endpoints.addressDetailNonLogin, {
            parameters: {
                shippingAddressId: this.shippingAddressId,
                deviceId: localStorage.uuid
            },
            onSuccess: (response: BaseResponse<AddressDetailResponse>) => {
                if (response.result) {
                    this.emit(() => {
                        this.isLoading = false;
                        this.name = response.data.name;
                        this.phone = response.data.phone;
                        this.email = response.data.email;
                        this.addressDetail = response.data.addressDetail;
                        this.addressLabel = response.data.pinpoint?.addressLabel;
                        this.longitude = response.data.pinpoint?.longitude;
                        this.latitude = response.data.pinpoint?.latitude;
                    });
                } else {
                    this.emit(() => {
                        this.isLoading = false;
                        this.errorMessage = response.message;
                    });
                }
            },
            onFailure: (errorMessage) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorMessage = errorMessage;
                });
            }
        });
    }

    checkIsPinpointAvailable(): boolean {
        return (this.addressLabel && this.latitude && this.longitude) ? true : false;
    }

    checkIsAllFieldNotEmpty(): boolean {
        return (
            (this.name.length > 0) &&
            (this.phone.length > 0) &&
            (this.email.length > 0) &&
            this.addressLabel &&
            this.latitude &&
            this.longitude &&
            (this.addressDetail.length > 0)
        ) ? true : false;
    }

    submitUpdate(onSuccess: () => void) {
        this.emit(() => {
            this.isSubmitting = true;
        });

        if (this.shippingAddressId) {
            requestAPI(localStorage.fpAuth ? Endpoints.addressUpdate : Endpoints.addressUpdateNonLogin, {
                parameters: {
                    shippingAddressId: this.shippingAddressId,
                    name: this.name,
                    phone: this.phone,
                    email: this.email,
                    addressDetail: this.addressDetail,
                    pinpoint: {
                        addressLabel: this.addressLabel,
                        latitude: this.latitude,
                        longitude: this.longitude
                    },
                    deviceId: localStorage.uuid
                },
                onSuccess: (response) => {
                    if (response.result) {
                        this.emit(() => {
                            this.isSubmitting = false;
                            onSuccess();
                        });
                    } else {
                        this.emit(() => {
                            this.isSubmitting = false;
                            this.errorSnackbarMessage = new ShowSnackbarModel({ message: response.message });
                        });
                    }
                },
                onFailure: (errorMessage) => {
                    this.emit(() => {
                        this.isSubmitting = false;
                        this.errorSnackbarMessage = new ShowSnackbarModel({ message: errorMessage });
                    });
                }
            });
        } else {
            requestAPI(localStorage.fpAuth ? Endpoints.addressAdd : Endpoints.addressAddNonLogin, {
                parameters: {
                    name: this.name,
                    phone: this.phone,
                    email: this.email,
                    deviceId: localStorage.uuid,
                    addressDetail: this.addressDetail,
                    pinpoint: {
                        addressLabel: this.addressLabel,
                        latitude: this.latitude,
                        longitude: this.longitude
                    }
                },
                onSuccess: (response) => {
                    if (response.result) {
                        this.emit(() => {
                            this.isSubmitting = false;
                            onSuccess();
                        });
                    } else {
                        this.emit(() => {
                            this.isSubmitting = false;
                            this.errorSnackbarMessage = new ShowSnackbarModel({ message: response.message });
                        });
                    }
                },
                onFailure: (errorMessage) => {
                    this.emit(() => {
                        this.isSubmitting = false;
                        this.errorSnackbarMessage = new ShowSnackbarModel({ message: errorMessage });
                    });
                }
            });
        }
    }
}