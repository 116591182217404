import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { ShowErrorModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { getPreloadProps } from "../../CommonComponents/Utils";
import TrackingResponse from "../OrderAndDelivery/Models/TrackingResponse";
import OrderDetailResponse from "./Models/OrderDetailResponse";

export default class OrderDetailViewModel extends ViewModel {
  orderId: string;
  isCreator: boolean = false;
  creatorId: string = "";
  numberInCart: number = 0;
  model: OrderDetailResponse | null = null;
  isLoading: boolean = true;
  errorMessage: ShowErrorModel | null = null;
  Message: string | null = null;
  isLoadingTracking: boolean = false;
  trackingData?: TrackingResponse;
  shouldShowTrackingModal: boolean = false;
  isLoadingReorderForBagItemId?: string;

  closeTrackingModal() {
    this.emit(() => {
      this.shouldShowTrackingModal = false;
    });
  }

  openTrackingModal() {
    this.emit(() => {
      this.shouldShowTrackingModal = true;
    });
  }

  constructor(orderId: string) {
    super();
    this.orderId = orderId;
    const params = new URLSearchParams(window.location.search);
    const focus = params.get("focus") || "";

    if (focus) {
      setTimeout(() => {
        this.openTrackingModal();
      }, 0);
      this.loadTracking(this.orderId, [focus]);
    }
  }

  reoder(bagItemIds: string[] = []) {
    this.emit(() => {
      this.isLoadingReorderForBagItemId = bagItemIds[0];
    });

    requestAPI(Endpoints.reorder, {
      onFailure: () => {
        this.emit(() => {
          this.isLoadingReorderForBagItemId = undefined;
        });
      },
      onSuccess: () => {
        this.emit(() => {
          this.isLoadingReorderForBagItemId = undefined;
        });
      },
      parameters: {
        fpAuth: localStorage.getItem("fpAuth"),
        bagItemIds,
      },
    });
  }

  loadTracking(orderId?: string, bagItemIds: string[] = []) {
    if (!orderId) return;

    this.emit(() => {
      this.isLoadingTracking = true;
      this.trackingData = undefined;
    });

    requestAPI(Endpoints.trackMyOrder, {
      onFailure: () => {
        this.isLoadingTracking = false;
      },
      onSuccess: (response: BaseResponse<TrackingResponse>) => {
        this.isLoadingTracking = false;
        this.trackingData = response.data;
        this.emit(() => {});
      },
      parameters: {
        fpAuth: localStorage.fpAuth,
        orderId: orderId,
        bagItemIds,
      },
    });
  }

  loadPreloadingProps() {
    this.emit(() => {
      this.isLoading = true;
    });

    getPreloadProps({
      onSuccess: (isCreator, creatorId, numberInCart) => {
        this.emit(() => {
          this.isCreator = isCreator;
          this.creatorId = creatorId;
          this.numberInCart = numberInCart;
        });

        this.loadOrderDetail();
      },
      onFailure: (error) => {
        this.emit(() => {
          this.isLoading = false;
          this.errorMessage = {
            message: error,
            onRetryButtonTapped: () => this.loadPreloadingProps(),
          };
        });
      },
    });
  }

  loadOrderDetail() {
    this.emit(() => {
      this.isLoading = true;
      this.errorMessage = null;
    });
    requestAPI(Endpoints.orderDetails, {
      parameters: {
        fpAuth: localStorage.fpAuth,
        orderId: this.orderId,
      },
      onSuccess: (response: BaseResponse<OrderDetailResponse>) => {
        console.log(response.data);
        this.emit(() => {
          this.isLoading = false;
          this.model = response.data;
        });
      },
      onFailure: (error: string) => {
        this.emit(() => {
          this.isLoading = false;
          this.errorMessage = {
            message: error,
            onRetryButtonTapped: () => this.loadOrderDetail(),
          };
        });
      },
    });
  }
}
