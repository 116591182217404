import React from "react";
import { useParams } from "react-router-dom";
import BaseUIComponent from "../../../CommonComponents/Base/BaseUIComponent";
import NavigationButton from "../../../CommonComponents/UI/ButtonWidget/NavigationButton";
import Scaffold from "../../../CommonComponents/UI/Scaffold/Scaffold";
import { ContentDisplayCell } from "../Widgets/ContentDisplayCell";
import ContentDisplayViewModel from "./ContentDisplayViewModel";

import "./ContentDisplayScreen.css";

import ic_thefepi_f from "../../../Assets/Images/Logo/ic_fepi_f.svg";
import ic_cart from "../../../Assets/Images/ic_cart.svg"
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from "swiper";

export default function ContentDisplayScreen() {
    let { id } = useParams();
    return (<ContentDisplayPage id={id ?? ""} />);
}

class ContentDisplayPage extends BaseUIComponent<{id: string}, ContentDisplayViewModel> {
    constructor(props: {id: string}) {
        super(props, new ContentDisplayViewModel(props.id));
        this.viewModel().loadPreloadingProps();
    }

    render() {
      return(
            <Scaffold 
                isShowLoading={this.viewModel().isLoading}
                showErrorModel={this.viewModel().errorMessage}
                showPopUpModel={this.viewModel().popUpModel}
                shareUrlModel={this.viewModel().shareUrlModel}
                showDownloadInvitationWidget={true}
            >
                <div id="ContentDisplayContainer">
                    <div id="ContentDisplayMainContent">
                        <Swiper  
                          style={{
                            height: '100%',
                            width: '100%',
                            zIndex: 0
                          }}
                          initialSlide={this.viewModel().model.findIndex((v) => v.contentId === this.props.id)}
                          direction={"vertical"}
                          mousewheel={true}
                          modules={[Mousewheel]}
                          slidesPerView={1}
                          onSlideChangeTransitionEnd={swiper => {
                              if (swiper.activeIndex === this.viewModel().model.length - 1 && this.viewModel().currentPage < this.viewModel().pages) {
                                this.viewModel().loadContent()
                              }
                        }}>
                        {
                            this.viewModel().model.map(m => <>
                              <SwiperSlide>
                                 <ContentDisplayCell 
                                    model={m} 
                                    onLoveButtonTapped={(id, isLiked) => this.viewModel().loveContent(id, isLiked)} 
                                    onFollowButtonTapped={(id) => this.viewModel().followCreator(id)}
                                    onShareButtonTapped={(url) => this.viewModel().onShareLink(url)}
                                    sellerId={this.viewModel().sellerId}
                                />
                              </SwiperSlide>
                            </>)
                        }
                        </Swiper>
                        <div id="ContentDisplayTopGradation"></div>
                        <div id="ContentDisplayTopTabRow">
                            <NavigationButton 
                                src={ic_thefepi_f} 
                                onClick={() => window.location.href = "/"} 
                            />  
                            <div className="FlexFillSpacing"></div>
                            <NavigationButton 
                                src={ic_cart} 
                                onClick={() => {
                                    window.location.href = "/Cart";
                                }} 
                                numberOnRightTop={this.viewModel().numberInCart} 
                            />
                        </div>
                    </div>
                </div>
            </Scaffold>
        );
    }
}