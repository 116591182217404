import React from "react";
import SizedDiv from "../../../CommonComponents/UI/SizedDiv";
import { uploadCommonFile } from "../../../CommonComponents/Utils";
import RadioFieldWidget from "../RadioField/RadioFieldWidget";
import RatingInputWidget from "../RatingInput/RatingInputWidget";
import ReviewPhotoWidget from "../ReviewPhoto/ReviewPhotoWidget";
import "./ToReviewFormWidget.css";

export type ToReviewWidgetFormFields = {
  rating?: number;
  review?: string;
  imageUrls?: { url?: string; state?: "UPLOADING" | "IDLE" }[];
  checkedProblems?: string[];
};

export type ToReviewFormWidgetProps = {
  productPhotoUrl?: string;
  productBrandName?: string;
  productName?: string;
  variant?: string;
  onStateChange?: (state: ToReviewWidgetFormFields) => void;
} & ToReviewWidgetFormFields;

function ToReviewFormWidget(props: ToReviewFormWidgetProps) {
  const {
    productBrandName,
    productName,
    productPhotoUrl,
    variant,
    review,
    rating = 0,
    imageUrls = [],
    onStateChange,
    checkedProblems = [],
  } = props;

  return (
    <div className="ToReviewFormWidgetContainer">
      <div className="ToReviewFormWidgetHeader">
        <div className="ToReviewFormWidgetProductPhotoContainer">
          <img
            src={productPhotoUrl}
            className="ToReviewFormWidgetProductPhoto"
            alt={productName}
          />
        </div>
        <div>
          <p className="ToReviewFormWidgetProductBrandName">
            {productBrandName}
          </p>
          <p className="ToReviewFormWidgetProductName">{productName}</p>
          <p className="ToReviewFormWidgetProductVariantName">{variant}</p>
        </div>
      </div>
      <div className="ToReviewFormWidgetSection">
        <p className="ToReviewFormWidgetSectionTitle">Overall Rating</p>
        <SizedDiv height={12} />
        <RatingInputWidget
          max={5}
          rating={rating}
          onStarClick={(index) => {
            onStateChange?.({
              rating: index + 1,
            });
          }}
        />
      </div>
      {rating < 3 && (
        <div className="ToReviewFormWidgetSection">
          <p className="ToReviewFormWidgetSectionTitle">
            What seems to be the problem?
          </p>
          <SizedDiv height={16} />
          <div className="ToReviewFormWidgetProblemsField">
            {[
              "Poor product quality",
              "Wrong product",
              "Slow delivery",
              "Others",
              "Incomplete order",
            ].map((label) => (
              <div>
                <RadioFieldWidget
                  label={label}
                  value={label}
                  onChecked={() => {
                    if (!checkedProblems.includes(label)) {
                      onStateChange?.({
                        checkedProblems: [...checkedProblems, label],
                      });
                    } else {
                      onStateChange?.({
                        checkedProblems: checkedProblems.filter(
                          (v) => v !== label
                        ),
                      });
                    }
                  }}
                  isChecked={checkedProblems.includes(label)}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="ToReviewFormWidgetSection">
        <p className="ToReviewFormWidgetSectionTitle">Your review</p>
        <p
          style={{
            color: "#999999",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "18px",
            marginTop: 8,
          }}
        >
          Write Review to get{" "}
          <span style={{ color: "#FF00CE" }}>5,000 points</span>
        </p>
        <textarea
          value={review}
          onChange={(e) => {
            if ((review?.length || 0) > 99) return;
            onStateChange?.({
                review: e.target.value,
              });
          }}
          className="ToReviewFormWidgetReviewInput"
        ></textarea>
        <p className="ToReviewFormWidgetCounterLabel">
          {review?.length || 0}/100
        </p>
        <div style={{ display: "flex" }}>
          {Array.from({ length: 3 }).map((_, index) => {
            return (
              <>
                <ReviewPhotoWidget
                  isLoading={imageUrls[index]?.state === "UPLOADING"}
                  imageUrl={imageUrls[index]?.url}
                  onFileChange={(file) => {
                    const newState = [...(imageUrls || [])];
                    newState[index] = {
                      url: URL.createObjectURL(file),
                      state: "UPLOADING",
                    };

                    uploadCommonFile(file).then((url) => {
                      const cpy = [...newState];

                      cpy[index] = {
                        url,
                        state: "IDLE",
                      };
                      onStateChange?.({
                        imageUrls: cpy,
                      });
                    });

                    onStateChange?.({
                      imageUrls: newState,
                    });
                  }}
                />
                <div style={{ width: 20 }}></div>
              </>
            );
          })}
        </div>
        <p
          style={{
            fontWeight: "400",
            fontSize: "10px",
            lineHeight: "15px",
            color: "#999999",
            marginTop: 8,
          }}
        >
          Add up to 3 photos. 5MB maximum per image.
        </p>
      </div>
    </div>
  );
}

export default ToReviewFormWidget;
