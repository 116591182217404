import StoreBrandProductsViewModel from "./StoreBrandProductsViewModel";
import { useParams } from "react-router-dom";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";

import "./StoreBrandProductsScreen.css";

import ic_photo_profile_placeholder from "../../Assets/Images/ic_photo_profile_placeholder.svg";
import ic_thefepi_f from "../../Assets/Images/Logo/ic_fepi_f.svg";
import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
import ic_sort from "../../Assets/Images/ic_arrow_sort.svg";
import ic_share from "../../Assets/Images/ic_share_box.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import { ImageWidget } from "../../CommonComponents/UI/ImageWidget/ImageWidget";
import { IndexIndicator } from "../../CommonComponents/UI/IndexIndicator/IndexIndicatorWidget";
import ProductSummaryItemCellWidget from "../../CommonComponents/UI/Cells/ProductSummaryItemCellWidget";
import { LoadingWidget } from "../../CommonComponents/UI/LoadingWidget/LoadingWidget";
import ViewController from "../../CommonComponents/Base/ViewController";
import StoreTopTabbarWidget from "../CommonComponents/Widgets/StoreTopTabbarWidget";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import CuratedProductsSortViewWidget from "../CuratedProducts/Widgets/CuratedProductsSortViewWidget";
import { DivWithIntersectionObserver } from "../../CommonComponents/UI/DivWithIntersectionObserver/DivWithIntersectionObserver";

export default function StoreBrandProductsScreen() {
  const { id } = useParams();

  return <StoreBrandProductsPage brandId={id ?? ""} />;
}

type StoreBrandProductsPageProps = {
  brandId: string;
};

class StoreBrandProductsPage extends ViewController<
  StoreBrandProductsPageProps,
  StoreBrandProductsViewModel
> {
  constructor(props: StoreBrandProductsPageProps) {
    super(props, new StoreBrandProductsViewModel(props.brandId));

    this.onEndReached = this.onEndReached.bind(this);
    this.viewModel.loadPreloadingProps();
  }

  onEndReached() {
    this.viewModel.loadProducts();
  }

  getCoverImageHeight() {
    return this.viewModel.coverImages.length === 0 &&
      !this.viewModel.brandImageUrl
      ? 108
      : (this.state.width * 3) / 2;
  }

  renderPill(props: {
    onClick?: () => void;
    label?: string;
    isActive?: boolean;
  }) {
    const { isActive = false, label, onClick } = props;

    return (
      <button
        onClick={() => void onClick?.()}
        style={{
          marginRight: "-8px",
          padding: "4px 12px",
          background: isActive ? "#383838" : "#FFFFFF",
          borderRadius: "20px",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "18px",
          textAlign: "center",
          color: isActive ? "#FFFFFF" : "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: isActive ? "unset" : "0.5px solid #383838",
          flexShrink: 0,
        }}
      >
        {label}
      </button>
    );
  }

  override render() {
    const coverImageHeight = (this.state.width * 3) / 2;

    return (
      <Scaffold
        isShowLoading={
          this.viewModel.isLoading && this.viewModel.products.length === 0
        }
        showErrorModel={this.viewModel.errorMessage}
        showSnackbar={this.viewModel.errorSnackbar}
        shareUrlModel={this.viewModel.shareUrlModel}
        isShowingBottomModal={this.viewModel.shouldShowSortModal}
        bottomModalChild={
          this.viewModel.sorts ? (
            <CuratedProductsSortViewWidget
              models={this.viewModel.sorts}
              selectedId={
                this.viewModel.selectedSortId ?? this.viewModel.sorts?.[0]?.id
              }
              onSelectSortId={(value) => this.viewModel.selectSortId(value)}
              onApplySortTapped={() => {
                this.viewModel.hideSortModal();
              }}
            />
          ) : undefined
        }
      >
        <div id="StoreBrandProductMainContentContainer">
          {this.viewModel.coverImages.length === 0 ? (
            <div
              id="StoreBrandProductsTopImageContainer"
              style={{ height: coverImageHeight, background: "#000" }}
            ></div>
          ) : (
            <Swiper
              id="StoreBrandProductsTopImageContainer"
              slidesPerView={1}
              style={{ height: `${coverImageHeight}px` }}
              onSlideChangeTransitionEnd={(swiper) => {
                this.viewModel.updateActiveIndex(swiper.activeIndex);
              }}
            >
              {this.viewModel.coverImages.map((imageUrl, index) => {
                return (
                  <SwiperSlide key={`BrandProductsTopImages_${index}`}>
                    <div style={{ height: `${coverImageHeight}px` }}>
                      <ImageWidget imageUrl={imageUrl} key={`${index}_image`} />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
          <div
            id="BrandProductsCoverBottomGradient"
            style={{ top: `${coverImageHeight - 50}px` }}
          />
          <div
            id="BrandProducstIndexIndicatorContainer"
            style={{ top: `${coverImageHeight - 24}px` }}
          >
            <IndexIndicator
              showingIndex={this.viewModel.showingIndex}
              totalOfIndices={this.viewModel.coverImages.length}
            />
          </div>
          <div
            style={{
              backgroundColor: "#FFF",
              borderRadius: "24px 24px 0px 0px",
              // zIndex: 4,
              position: "relative",
              top: -24,
            }}
          >
            {this.viewModel.brandImageUrl && (
              <div id="BrandProducstProfilePicture" style={{ top: `-40px` }}>
                <ImageWidget
                  imageUrl={this.viewModel.brandImageUrl}
                  placeholder={ic_photo_profile_placeholder}
                />
              </div>
            )}
            {/* <div id="StoreBrandProductShareButtonContainer"style={{height:"40px"}}>
                        <button onClick={() => this.viewModel.onShareLink()}>
                            <img src={ic_share} alt="" style={{height: 18, width: 20}} />
                        </button>
                    </div> */}
            <p
              id={
                this.viewModel.brandImageUrl
                  ? "StoreBrandProductsName"
                  : "BrandNameWithoutImages"
              }
            >
              {this.viewModel.brandName}
            </p>
            {this.viewModel.brandDescription.length > 0 && (
              <p id="StoreBrandProductsDescription">
                {this.viewModel.brandDescription}
              </p>
            )}
            {(this.viewModel.primaryCategories?.length || 0) > 0 && (
              <>
                <StoreTopTabbarWidget
                  selectedId={
                    this.viewModel.selectedPrimaryCategoryId ||
                    this.viewModel.primaryCategories?.[0]?.id
                  }
                  labels={
                    this.viewModel.primaryCategories?.map((cat) => ({
                      id: cat.id || "",
                      label: cat.label || "",
                      onTap: () => {
                        this.viewModel.selectPrimaryCatId(cat.id);
                      },
                    })) || []
                  }
                />
                <SizedDiv height={20} />
              </>
            )}

            {(this.viewModel.secondaryCategories?.length || 0) > 0 && (
              <>
                <StoreTopTabbarWidget
                  shouldShowBottomBorder={false}
                  renderTabButton={this.renderPill}
                  selectedId={
                    this.viewModel.selectedSecondaryCategoryId ||
                    this.viewModel.secondaryCategories?.[0]?.id
                  }
                  labels={
                    this.viewModel.secondaryCategories?.map((cat) => ({
                      id: cat.id || "",
                      label: cat.label || "",
                      onTap: () => {
                        this.viewModel.selectSecondaryCatId(cat.id);
                      },
                    })) || []
                  }
                />
                <SizedDiv height={16} />
              </>
            )}
            {(this.viewModel.sorts?.length ?? 0) > 0 && (
              <div id="CuratedProductsSelectedSortDisplayed">
                <button onClick={() => this.viewModel.showSortModal()}>
                  <img src={ic_sort} alt="" />
                  <div style={{ width: "4px" }} />
                  {this.viewModel.sorts?.find(
                    (value) => value.id === this.viewModel.selectedSortId
                  )?.label ?? this.viewModel.sorts?.[0]?.label}
                </button>
              </div>
            )}
            <div id="BrandProductsListContainer">
              {this.viewModel.products.map((model) => (
                <ProductSummaryItemCellWidget
                  key={`BrandProducts_${model.id}`}
                  cellWidth={(this.state.width - 36) / 2}
                  model={model}
                  badgeUrls={model.badgeUrls}
                />
              ))}
              {this.viewModel.products.length === 0 && (
                <SizedDiv height={360} />
              )}
            </div>
            {this.viewModel.totalOfItems > this.viewModel.products.length && (
              <DivWithIntersectionObserver
                onVisibilityChanged={this.onEndReached}
              >
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                ></div>
              </DivWithIntersectionObserver>
            )}
            {this.viewModel.isLoading && <LoadingWidget />}
          </div>
        </div>
        <div
          id="SpaceNavigationBackground"
          style={{ opacity: `${this.viewModel.navBarTransparency}` }}
        >
          {this.viewModel.navBarTransparency === 1 && (
            <>
              <p id="SpacePageTitle">{this.viewModel.brandName ?? ""}</p>
            </>
          )}
        </div>

        <div id="RightButtonsContainer">
          <button
            id="CartFloatingButton"
            style={{ background: "rgba(255,255,255,1)" }}
            onClick={() => {
              window.location.href = "/Cart";
            }}
          >
            <img src={ic_cart} alt="" />
            {(this.viewModel.numberInCart ?? 0) > 0 && (
              <div>{this.viewModel.numberInCart}</div>
            )}
          </button>
          <button
            id="ShareFloatingButtonContainer"
            onClick={() => {
              this.viewModel.onShareLink();
            }}
          >
            <img src={ic_share} alt="" style={{ height: 18, width: 20 }} />
          </button>
        </div>

        <button
          id="SpaceHomeButton"
          style={{ background: "rgba(255,255,255,1)" }}
          onClick={() => {
            if (true) {
              window.history.back();
            } else {
            }
          }}
        >
          <svg
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.12082 0.500429C6.97002 0.506009 6.82756 0.566692 6.72392 0.669485L0.373578 6.62027C0.357951 6.63082 0.342899 6.64211 0.328476 6.65408C0.141212 6.87103 0.160744 7.18586 0.373578 7.38102L6.7149 13.3318C6.8589 13.4737 7.07349 13.5316 7.27597 13.4832C7.47845 13.4348 7.63723 13.2877 7.69111 13.0985C7.74499 12.9093 7.68557 12.7076 7.53575 12.571L2.16863 7.54162H17.2683C17.4765 7.54438 17.6701 7.44188 17.7751 7.27337C17.88 7.10485 17.88 6.89643 17.7751 6.72792C17.6701 6.55941 17.4765 6.4569 17.2683 6.45966H2.16863L7.54477 1.43024C7.71709 1.27431 7.76877 1.03488 7.67465 0.828459C7.58053 0.622042 7.36017 0.491524 7.12082 0.500429Z"
              fill="black"
              stroke="black"
              stroke-width="0.2"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </Scaffold>
    );
  }
}
