import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import BillingResponse from "./Models/BillingResponse";
import CartResponse from "./Models/CartResponse";
import { ShowErrorModel, ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { getPreloadProps } from "../../CommonComponents/Utils";
import $ from "jquery";
import { v4 as uuidv4 } from 'uuid';
import CheckoutResponse from "../Checkout/Models/CheckoutResponse";
import ViewModel from "../../CommonComponents/Base/ViewModel";

export default class CartViewModel extends ViewModel {
    cartData : CartResponse | null = null;
    billingData : BillingResponse | null = null;
    numberInCart: number = 0;
    isLoading: boolean = true;
    isLoadingButtonSubmit: boolean = false;
    errorModel: ShowErrorModel | null = null;
    errorMessage: string | null = null;
    Message: string | null = null;
    isSuccess: boolean = false;
    showModal: boolean = false;
    snackbarMessage: ShowSnackbarModel | null = null;
    isEnableBtn: boolean = false;
    bag_item_id: string = '';
    bag_item_ids: Array<String> = [];
    navBarTransparency: number = 1;
    showModalLogin: boolean = false;
    showModalBenefit: boolean = false;
    shouldShowErrorModal: boolean = false;
    errorModalMessage: string = "";
    errorModalLink?: string = "";

    changeNavBarTransparency(value: number) {
        this.navBarTransparency = value;
        this.emit(() => {});
    }

    loadPreloadingProps() {
        this.isLoading = true;
        localStorage.removeItem("selectedBagItem");
        localStorage.removeItem("address_id");
        localStorage.removeItem("address_id_edit");
        localStorage.removeItem("address_name");
        localStorage.removeItem("scity_edit");
        localStorage.removeItem("scity_id_edit");
        localStorage.removeItem("ship_name");
        localStorage.removeItem("ship_phone");
        localStorage.removeItem("ship_email");
        localStorage.removeItem("ship_address");
        localStorage.removeItem("ship_postCode");
        localStorage.removeItem("courier_id");
        localStorage.removeItem("courier_name");
        localStorage.removeItem("includeShippingInsurance");
        localStorage.removeItem("point");
        localStorage.removeItem("use_coupon");
        localStorage.removeItem("couponId");
        localStorage.removeItem("couponName");
        localStorage.removeItem("deliveryNotes");

        getPreloadProps({
            onSuccess: (_, __, numberInCart) => {
                this.numberInCart = numberInCart;
                this.emit(() => {});

                if(localStorage.uuid === '' || localStorage.uuid === undefined)
                {
                    localStorage.setItem("uuid", uuidv4())
                }

                this.loadCart();
            },
            onFailure: (error) => {
                this.isLoading = false;
                this.errorModel = {
                    message: error,
                    onRetryButtonTapped: () => this.loadPreloadingProps()
                };

                this.emit(() => {});
            }
        })
    }

    loadCart() {
        this.isLoading = true;
        this.emit(() => {});

        requestAPI(Endpoints.bag, {
            parameters: {
                deviceId: localStorage.uuid,
                fpAuth: localStorage.fpAuth
            },
            onSuccess: (response: BaseResponse<CartResponse>) => {
                this.cartData = response.data;
                localStorage.setItem("orderId", response.data.trNo)
                
                this.isLoading = false;
                localStorage.setItem("selectedBagItem", JSON.stringify(response.data.items.map(i => i.bagItemId)))
                // localStorage.setItem("selectedBagItem", "[")
                // response.data.items.map((items,index) => (
                //     index === 0 ?
                //         localStorage.setItem("selectedBagItem", localStorage.selectedBagItem+items.bagItemId+'')
                //     :
                //         localStorage.setItem("selectedBagItem", localStorage.selectedBagItem+','+items.bagItemId)
                // ))
                // localStorage.setItem("selectedBagItem",localStorage.selectedBagItem+"]");

                if(localStorage.selectedBagItem !== "[undefined]" && localStorage.selectedBagItem !== "[]"){
                    this.isEnableBtn = true;
                }else{
                    this.isEnableBtn = false;
                }

                this.emit(() => {});
                this.loadBilling();
            },
            onFailure: (error: string) => {
                this.isLoading = false;
                this.errorModel = {
                    message: error,
                    onRetryButtonTapped: () => this.loadPreloadingProps()
                };

                this.emit(() => {});
            }
        })
    }

    loadBilling() {
        this.emit(() => this.isLoading = true);

        requestAPI(localStorage.fpAuth === undefined ? Endpoints.bagBillingDevice : Endpoints.bagBilling, {
            parameters: {
                deviceId: localStorage.uuid,
                fpAuth: localStorage.fpAuth,
                selectedBagItem: JSON.parse(localStorage.selectedBagItem)
            },
            onSuccess: (response: BaseResponse<CartResponse>) => {
                this.isLoading = false;
                this.billingData = response.data.billing;
                this.emit(() => {});
            },
            onFailure: (error: string) => {
                this.isLoading = false;
            }
        })
    }

    onChecked = (bagItemId: string) => {
        let bag_item_id: any[] = [];
        $.each($('.bag_item_id:checked'), function() {
            bag_item_id.push($(this).val()); 
        });
        
        localStorage.setItem("selectedBagItem", JSON.stringify(bag_item_id))

        // localStorage.setItem("selectedBagItem", "["+localStorage.selectedBagItem)
        
        // bag_item_id.map((items,index) => (
        //     bag_item_id.length > 1
        //     ?
        //         index === 0 ?
        //             localStorage.setItem("selectedBagItem", localStorage.selectedBagItem+items)
        //         :
        //             localStorage.setItem("selectedBagItem", localStorage.selectedBagItem+','+items)
        //     :
        //         localStorage.setItem("selectedBagItem", '['+items+']')
        // ))
        // localStorage.setItem("selectedBagItem",localStorage.selectedBagItem+"]");
        
        requestAPI(localStorage.fpAuth === undefined ? Endpoints.bagBillingDevice : Endpoints.bagBilling, {
            parameters: {
                deviceId: localStorage.uuid,
                fpAuth: localStorage.fpAuth,
                selectedBagItem: bag_item_id
            },
            onSuccess: (response: BaseResponse<CartResponse>) => {
                this.isLoading = false;
                this.billingData = response.data.billing;
                this.emit(() => {});
            },
            onFailure: (error: string) => {
                this.isLoading = false;
                this.errorModel = {
                    message: error,
                    onRetryButtonTapped: () => this.loadPreloadingProps()
                };

                this.emit(() => {});
            }
        })
    }

    onAddToBag = (flag: number,bagItemId: string,quantity: number) => {
        var qty = 0;
        flag === 1 ? qty = quantity-1 : qty = quantity+1;
        
        if(qty === 0)
        {
            this.bag_item_id = bagItemId;
            this.showModal = true;
            this.emit(() => {});
            return;
        }
        
        requestAPI(Endpoints.bagUpdate, {
            parameters: {
                "fpAuth": localStorage.fpAuth,
                "bagItemId": bagItemId,
                "quantity": qty,
                "deviceId" : localStorage.uuid
            },
            onSuccess: (response: BaseResponse<CartResponse>) => {
                this.loadCart();
            },
            onFailure: (error: string) => {
                this.Message = error;
                
                this.emit(() => {});
            }
        })
    }

    onRemoveItem = (bagItemId: string) => {
        requestAPI(Endpoints.bagDelete, {
            parameters: {
                "deviceId" : localStorage.uuid,
                "fpAuth": localStorage.fpAuth,
                "bagItemId": bagItemId,
                "quantity": 0
            },
            onSuccess: (response: BaseResponse<CartResponse>) => {
                document.body.classList.remove('modal-open');
                this.showModal = false;
                this.emit(() => {});
                this.loadCart();
            },
            onFailure: (error: string) => {
                document.body.classList.add('modal-open');
                this.showModal = true;
                this.Message = error;
                this.emit(() => {});
            }
        })
    }

    getAppStoreLink() {
      if (window.navigator.userAgent.includes("Android")) {
        return "https://play.google.com/store/apps/details?id=com.thefepi.shopable"
      } else if (window.navigator.userAgent.includes("iPhone") || window.navigator.userAgent.includes("iPad") || window.navigator.userAgent.includes("Mac")) {
        return "https://apps.apple.com/id/app/thefepi/id1590816062"
      }
    }

    onCloseErrorModal() {      
      this.shouldShowErrorModal = false;
      this.emit(() => {});
    }

    onHandleCheckout() {
      this.isLoadingButtonSubmit = true;
      this.emit(() => {});

      const selectedItems = JSON.parse(localStorage.getItem("selectedBagItem") || "[]") as string[];

      requestAPI(Endpoints.bagConfirm, {
        parameters: {
          fpAuth: localStorage.fpAuth,
          selectedBagItems: this.cartData?.items.map((x) => ({
            bagItemId: x.bagItemId,
            quantity: x.quantity
          })).filter(i => selectedItems.includes(i.bagItemId))
        },
        onSuccess: () => {
          if(localStorage.fpAuth === '' || localStorage.fpAuth === undefined) {
              this.showModalBenefit = true;
              this.emit(() => {});
            } else {
              window.location.href = "/Checkout";
            }            
            this.isLoadingButtonSubmit = false;
        },
        onFailure: (error: string, errorCode?: number) => {
          if (errorCode === 1003) {
            this.errorModalMessage = error;
            this.shouldShowErrorModal = true;
            this.errorModalLink = this.getAppStoreLink();
          }
          this.errorMessage = error;          
          this.isLoadingButtonSubmit = false;
          this.emit(() => {});
        },
      })

    }

    onCloseModal() {
        this.showModal = false;
        this.showModalLogin = false;
        this.showModalBenefit = false;
        this.emit(() => {});
    }

    onSnackbarIsFinished() {
        this.errorMessage = null;
    }
}