export default abstract class BaseViewModel {
    states: this[] = [];

    updateState: (args: any) => void = (_) => {
        this.states.push({...this});
        this.stabPostUpdateState();
    };

    abstract copy<T extends {}>(args: T): this;

    setupUpdateState(action: (args: any) => void) {
        this.updateState = action;
    }

    stabPostUpdateState() {} // For Testing Only
}