import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { ShowErrorModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { getPreloadProps } from "../../CommonComponents/Utils";
import { ProductBadgeType } from "../../CommonTypes";
import StoreMainHomeResponse from "./Models/StoreMainHomeResponse";

export default class StoreMainHomeViewModel extends ViewModel {
    groupId: string;
    isCreator: boolean = false;
    creatorId: string = "";
    numberInCart: number = 0;
    model: StoreMainHomeResponse | null = null;
    isLoading: boolean = true;
    promotionBanner: {
      imageUrl?: string;
      deeplink?: string;
    } = {};
    isLoadingPromotionBanner?: boolean = false;
    errorMessage: ShowErrorModel | null = null;
    
    badgeUrlsMap?: {[key in ProductBadgeType]: string};

    constructor(groupId: string) {
        super();
        this.groupId = groupId;
    }

    loadPromotionBanner() {
      this.emit(() => {
        this.isLoadingPromotionBanner = true;
      })
      requestAPI(Endpoints.promoBanner, {
        onSuccess: (data: any) => {
          
          this.emit(() => {
            this.isLoadingPromotionBanner = false;
          })
          this.emit(() => {
            this.promotionBanner.deeplink = data.data.deeplink;
            this.promotionBanner.imageUrl = data.data.imageUrl; 
          })
        },
        onFailure: () => {
          
        }
      })
    }

    loadPreloadingProps() {
        this.emit(() => { 
            this.isLoading = true;
        });

        getPreloadProps({
            onSuccess: (isCreator, creatorId, numberInCart, bdgm) => {
                this.emit(() => {
                    this.isCreator = isCreator;
                    this.creatorId = creatorId;
                    this.numberInCart = numberInCart;
                    this.badgeUrlsMap = bdgm
                });

                this.loadStoreContent();
            },
            onFailure: error => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorMessage = {
                        message: error,
                        onRetryButtonTapped: () => this.loadPreloadingProps(),
                    };
                });
            },
        });
    }

    loadStoreContent() {
        this.emit(() => {
            this.isLoading = true;
            this.errorMessage = null;
        });

        requestAPI(Endpoints.storeMainHome, {
            parameters: {
                productGroupId: this.groupId.length > 0 ? this.groupId : null,
            },
            onSuccess: (response: BaseResponse<StoreMainHomeResponse>) => {
                this.emit(() => {
                    this.isLoading = false;
                    
                    this.model = response.data;

                    if (this.groupId.length === 0) {
                        this.groupId = response.data.productGroups[0].id;
                    }
                });
            },
            onFailure: (error: string) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorMessage = {
                        message: error,
                        onRetryButtonTapped: () => this.loadStoreContent()
                    };
                });
            }
        });
    }
}